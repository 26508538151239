@import "./bower/bootstrap/less/bootstrap";
@import "./bower/font-awesome/less/font-awesome";
@import "fonts";
@import (reference)  "variables";

.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
}

.text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.web-link-nw {
  display: inline-block;
  width: 200px;
  .text-overflow();
}

.whs-nw {
  white-space: nowrap;
}

[href="#disabled"] {
  cursor: text;
}

.ir br {
  display: none;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}


// Floats
.float-right {
  float: right;
  margin: 0 0 @line-height-computed @line-height-computed;
  clear: right; // This ensures images don't 'bump' into each other
}

.float-left {
  float: left;
  margin: 0 @line-height-computed @line-height-computed 0;
  clear: left; // This ensures images don't 'bump' into each other
}

.float-center {
  float: none;
  margin: 20px auto;
}

.float-none {
  float: none; // if anyone knows why these are "important", let me know
  margin: 20px 0;
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

div.toolbar {
  float: left;
  .select {
    margin-right: 5px;
  }
}

/**	----------------------------------------
/**	Environment Indicator
/**	----------------------------------------*/
.env-indicator {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;
  color: #fff;
  border-radius: 5px 0 0 0;
  padding: 2px 5px;
  font-size: 11px;
}

.env-indicator.env-dev {
  background: @state-success-text;
}

.env-indicator.env-staging {
  background: @state-warning-text;
}

.btn-edit-this {
  font-size: @font-size-base;
  &:after {
    .fa-icon();
    content: @fa-var-edit;
  }  
  padding: 5px 6px;
}


.alert {
  padding-left: 40px;
  position: relative;

  &:before {
    .fa-icon();
    position: absolute;
    top: 10px;
    left: 12px;
    font-size: 1.5em;
  }

  &-success:before, &-info:before {
    content: @fa-var-info-circle;
  }

  &-warning:before, &-danger:before {
    content: @fa-var-exclamation-circle;
  }
}
/**	----------------------------------------
/** STRUCTURE
/**	1. Layout & Structure
/**	2. Modular components
/**	3. Page Specific
/**	---------------------------------------- */

.col-bdr, .col-bdl, .col-bdt, .col-bdb {position: relative;}

.col-bdr:after,
.col-bdl:before,
.col-bdt:before,
.col-bdb:after {
  content: "";
  position: absolute;
  line-height: 1px;
  border: 0 solid @giz-gray-60;
}

.col-bdr:after,
.col-bdl:before {
  height: 100%;
}

.col-bdb:after,
.col-bdt:before {
  left: 15px;
  width: calc(~'100% - {@grid-gutter-width}');
}

.col-bdr:after {
  right: 0;
  top: 0;
  border-right-width: 1px;
}

.col-bdl:before {
  left: 0;
  border-left-width: 1px;
}

.col-bdb:after {
  bottom: 0;
  border-bottom-width: 1px;
}

.col-bdt:before {
  top: 0;
  border-top-width: 1px;
}

.col-bdr + .col-bdl:before {
  left: -1px;
}

.col-bdl + .col-bdr:after {
  right: -1px;
}

.col-bdl-md-up:before,
.col-bdr-md-up:after {
  @media (max-width: @screen-sm-max) {
    border-width: 0;
  }
}

.col-bdl-sm-up:before,
.col-bdr-sm-up:after {
  @media (max-width: @screen-sm) {
    border-width: 0;
  }
}

//dashboard 
.list-stats {
  list-style-type: none;
  margin: 0;
  padding: 0;
  .clearfix;

  li {
    display: inline-block;
    position: relative;
    width: percentage(3/12);
    padding: 5px;
  }
}

.list-stats-item {
  // padding: 15px;
  border: 1px solid @giz-seagreen;
}

.list-stats-title {
  border-bottom: 1px solid @giz-seagreen;
  padding: 10px 15px;
  position: relative;
  small {
    display: block;
    position: absolute;
    top: 2px;
  }
  background-color: @giz-limegreen;
  color: #fff;
}
.list-stats-body {
  padding: 5px 15px;
  
}

.list-stats-footer {
  padding: 5px 15px;
  border-top: 1px solid @giz-seagreen;
  a {
    text-transform: uppercase;
    &:hover {
      color: @giz-seagreen;
    }
  }
}


.list-stats-number {
  font-size: 2em;
  text-align: center;
  padding: 10px 15px;
}



.form-inline .form-action {
  @media (min-width: @screen-sm) {
    padding-left: 12px;
    padding-top: 65px;
  }
}


/**	----------------------------------------
/**	3. LAYOUT & STRUCTURE
/** Page structures & layout
/**	----------------------------------------*/

body {
  -webkit-font-smoothing: antialiased;
}

html, body {
  height: 100%;
  @media (max-width: @screen-sm) {
    overflow-x: hidden; 
  }
}

#container {
  min-height: 100%;
  margin-bottom: -478px;
  padding-bottom: 478px;
}

#header {
  padding: 10px 0 0;
  .header-social {
    margin: 0;
    text-align: center;
    float: right;
  }
}

#header-logo {
  display: inline-block;
  margin-bottom: 10px;
  height: 110px;
}

.header-logo {
  min-width: 100%;
  width: 100%;
}

#footer {
  &.page-loading {
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: #fff;
    z-index: 1000;
    opacity: 0;
  }

  #footer-bar {
    background-color: #f5f5f5;

    @media print {
      border-top: 1px solid #f5f5f5;
    }

    p {
      font-size: 13px;
    }
    .quick-link {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
      li {
        font-size: 14px;
        margin-bottom: 7px;
      }

    }
    img {
      margin-bottom: 5px;
    }
    padding: 20px 0;

    .header-social {
      .text-center;
      
      a {
        color: @giz-seagreen;
      }
    }
  }
  .footer-top {
    border-top: 1px solid #C6C6C6;
    .footer-whois {
      text-align: center;
      color: @giz-limegreen;
      padding: 27px 0;
    }
  }
  .footer-bottom-stripe {
    background: url('@{assets_imgs}footer-bottom-stripe.png') center center no-repeat;
    height: 29px;

    @media print {
      display: none;
    }
  }
}



#footer .footer-whois {
  a {
    color: @headings-link-color;
  }
  a:hover {
    text-decoration: underline;
    color: @headings-link-hover-color;
  }
}

/* ========================================= */

/**	----------------------------------------
/**	2. Modular components
/** Modular blocks & re-usable components
/**	----------------------------------------*/

// Media Cntd.
// ------------------------------------
.media {
  border-bottom: 1px solid @gray-lighter;
  margin-bottom: @grid-gutter-width;
  position: relative;

  &:not(.media-bdb-dotted):not(.media-bd) {
    &:last-child {
      border-bottom: 0;
    }
  }
}

.panel .media-bdb-dotted {
  padding-bottom: 10px;
  border-bottom-style: dotted;
  border-bottom-color: @gray-lighter;
  margin-bottom: 0;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.media-form-group {
  .media-left, .media-body {
    position: relative;
  }
  .media-body {
    padding-left: @padding-small-horizontal;
    padding-right: @padding-small-horizontal;
  }
}

.media-bdb-dotted {
    border-bottom: 1px dotted @gray-light;
    margin-bottom: (@grid-gutter-width / 2);
    padding-bottom: (@grid-gutter-width / 2);
}

.media-bdb-dotted-xs {
@media screen and (min-width: @screen-sm) {
    border-bottom: 0px dotted @gray-dark;
    margin-bottom: 0;
    padding-bottom: 0;
}
}

a.media {
  border-width: 0;
}
.media-label {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: @giz-seagreen-dark;
  color: #fff;
  padding: 4px 8px;
}

.media-theme {
  // background-color: #f5f5f5;
  // border-radius: 5px 5px 5px 0;
  .box-shadow(0 1px 1px rgba(0,0,0,.05));
  border: 1px solid #f5f5f5;
  .media-body {
    padding: 6px;
  }
}

.media-image {
  &-left, &-right {
    padding-bottom: 15px;
  }

  @media (max-width: @screen-sm) {
    &-left, &-right {
      padding-bottom: 0;
      width: 120px;
    }
    &-left {
      float: left;
      padding-right: 15px;
    }
    &-right {
       float: right;
       padding-left: 15px;
    }
  }

}

.media-image-left-xs {
  width: 80px;
  float: left;
  padding-right: 15px;
}

.media-image-left-sm {
  width: 160px;
  float: left;
  padding-right: 15px;
  .media-object {
    max-width: 100%;
  }
}

.media-edit {
  position: absolute;
  bottom: 0;
  right: -6px;
}

// Metadata at the bottom of an article/page
// ------------------------------------
.meta {
  display: block;
  font-size: 12px;
  list-style: none;
  margin: 0 0 8px;
  padding: 0;
  line-height: @line-height-small;
  b, strong {
    font-weight: 600;
  }
}

.meta-org-link + .meta-org-text {
  display: none;
}

.media-meta-item + .media-meta-item:not(:empty) {
  &:before {
    content: '|';
    color: @text-color;
    padding: 0 2px;
  }
  &.media-meta-item-byline:before {
    content: "| by";
  }
}



h1:first-child {
  margin-top: 0;
}

.section-header,
.page-heading {
  margin-bottom: 35px;
  h1, h2, h3 {
    margin-top: 0;
  }
  .meta {
    font-size: @font-size-small;
  }
}


.section-header {
  margin-bottom: @line-height-computed;
}

.section-title {
  margin-top: 0;
}

.meta-info {
  display: inline-block;
  &:hover, &:focus {
    text-decoration: none;
  }
}

.page-header {
  margin-top: 0px;
  padding-bottom: 20px;
  h2, h3 {
    margin-top: 0;
  }
  .lead {
    margin-bottom: 0;
  }
}

.dashboard-heading {
  margin-top: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: @headings-font-family;
  color: @headings-color;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: @headings-link-color;
  &:hover {
    color: @headings-link-hover-color;
  }
}

h1 {
  font-size: 48px;
  color: @giz-limegreen;
}

.h2 {
  font-size: 32px;
}


h2 {
  font-size: 30px;
  line-height: 36px;
  &.media-title {
    font-size: 21px;
    line-height: 27px;
  }
}


.h3, .h4 {
  a {
    color: @link-color;
    &:hover {
      color: @link-hover-color;
    }
  }
}

a:hover {
  text-decoration: none;
}

div.lead {
  font-size: 18px;
}

.date-label {
  margin-top: 10px;
}


.text-left-i {
  text-align: left !important;
}

// Metadata at the top of an article/page ie. published date
// ------------------------------------
.media-meta {
  list-style: none;
  padding: 0;

  // margin-bottom: @line-height-base;
  font-size: @font-size-small;
  color: @gray;
  font-weight: 600;

  .date {
    font-weight: bold;
  }

  li {
    display: block;
    float: left;
    padding-right: 20px;
  }
  .addthis_toolbox {
    width: 120%;
    .addthis_counter.addthis_bubble_style {
      width: 36px !important;
    }
  }
  .clearfix;
}
.badge-primary {
  background-color: @giz-seagreen;
}

.blog {
  .page-heading h1 {
    margin-bottom: 5px;
  }
}

.media-meta-blog {
  font-size: 14px;
}

/* SEARCH BUTTONS */


.listing_grid {
  li {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

/*
 * Off Canvas
 * --------------------------------------------------
 */
@media screen and (max-width: 767px) {
  .row-offcanvas {
    position: relative;
    transition: all .25s ease-out;
  }

  .row-offcanvas-right {
    right: 0;
  }

  .row-offcanvas-left {
    left: 0;
  }

  .row-offcanvas-right .sidebar-offcanvas {
    right: -50%; /* 6 columns */
    padding-right: @grid-gutter-width;
  }

  .row-offcanvas-left .sidebar-offcanvas {
    left: -50%; /* 6 columns */
    padding-left: @grid-gutter-width;
  }

  .row-offcanvas-right.active {
    right: 50%; /* 6 columns */
  }

  .row-offcanvas-left.active {
    left: 50%; /* 6 columns */
  }

  .sidebar-offcanvas {
    position: absolute;
    top: 0;
    width: 50%; /* 6 columns */
  }
}

// Landing page framework
// -------------------------
.grid_item {
  &.border_top {
    margin-top: 0;
    padding-top: 9px;
    border-top: 1px solid #d5d5d5;
  }

  &.border_bottom {
    margin-bottom: 0;
    padding-bottom: 9px;
    border-bottom: 1px solid #d5d5d5;
  }

  &.border_left {
    border-left: 1px solid #d5d5d5;
    padding-left: 9px;
    margin-left: 0;
  }

  &.border_right {
    border-right: 1px solid #d5d5d5;
    margin-right: 0;
    padding-right: 9px;
  }


  &.bg_tint {

    .box {
      margin-bottom: 20px;
    }

  }

}

// Video container for inline videos
// -------------------------
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  &.slide-video {
    padding-bottom: 0;
    height: 350px;
  }

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Articles
// -------------------------

.article {


  @media (max-width: @screen-xs) {
    word-wrap: break-word;
  }

  h2:first-of-type {
    margin-top: 0;
  }
  a {
    word-wrap: break-word;
  }
  ol, ul {
    li:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

// Images
// -------------------------

.text-lg {
  font-size: floor((100% * @font-size-base / @font-size-small));
}

#adv-custom-caption {
  margin-top: 0;
  font-weight: bold;
  display: block;
  margin-bottom: 15px;
  min-height: 34px;

  font-size: @font-size-small;
  color: @giz-seagreen;

  p {
    margin-bottom: 0;
    line-height: 13px;

  }
}

.article-image, .video {
  padding: 0; // We need to override the grid padding on images, e.g. <img class="img article-image col-sm-6">
  margin-top: 4px; // adjust to ensure top of image in line with top of text

  .caption {
    margin-top: 7px;
    font-weight: bold;
    display: block;
    font-size: @font-size-small;
    color: @giz-seagreen;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .article-image-object {
    display: block;
    
  }

  .img-responsive {
    margin-left: auto;
    margin-right: auto;
  }

  margin-bottom: @line-height-computed;
}

.img-logo {
  border-radius: 15px 15px 15px 0;
  border: 3px solid #d9d8d8;
}

.image-group-grid {

  .col-sm-6:nth-child(odd) {
    padding-left: 0;
  }

  .col-sm-6:nth-child(even) {
    padding-right: 0;
  }

  .clearfix;

}

// any article images mobile (xs) do not have floats and scale 100%)

@media (max-width: @screen-xs-max) {

  .article-image {
    &.float-left, &.float-right {
      float: none;
      margin-left: 0;
      margin-right: 0;
    }
     
  }

  .image-group-grid {
    .img {
      float: none;
      margin: 0;
      padding: 0 !important;

    }
  }
}

// Boostrap Buttons
// -------------------------

.btn-submit {
  letter-spacing: 1px;
}

// Read more buttons
// -------------------------
.readmore {
  font-family: @font-family-sans-serif;
  font-weight: 700;
  color: @giz-orange;
  line-height: 19px;

  &:hover {
    text-decoration: none;
    color: @giz-orange;
  }

  // Reverse out if on dark colour
  &.inverse {
    background: #fff;
    color: @gray-dark;
  }

  &.inverse:hover {
    color: @gray-darker;
  }
}

.tweet-button {
  background-color: #55ACEE;
  display: block;
  width: 94px;
  margin-top: 5px;
  padding: 3px 5px;
  font-weight: 700;
  color: @white;
  text-transform: uppercase;
  &:hover {
    color: @white;
    background-color: darken(#55ACEE, 10%);
  }
}

.file-download {
  margin: 0 !important;
  li {
    width: 180px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
    background: @gray-light;
    border: 1px solid #CCC;
    padding: 5px;

    img {
      margin: 0 auto;
      display: block;
    }
    .pdf-title {
      font-size: 14px;
      font-family: @headings-font-family;
      font-weight: 700;

      .pdf-icon {
        background: url('../img/pdf-icon.png');
        width: 15px;
        height: 20px;
        margin-right: 5px;
        display: inline-block;
        margin-bottom: -3px;
      }
    }
  }
}

// Pull quotes

.quote-block {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.panel.pullquote {
  border: 1px solid @giz-gray-60;
  .quote {
    display: block;
    font-size: 20px;
    font-weight: 300;
    color: @giz-seagreen;
  }
  .attribution {
    display: block;
    font-size: 13px;
    font-weight: 600;
    color: @giz-limegreen;
    float: right;
  }
}

// Site Map
// ----------------------
.site-map {
  .site-map-list {
    li {
      width: 80px;
      margin-right: 20px;
      font-size: 20px;
      a {
        color: @text-color;
      }
    }
    li.last_child {
      margin-right: 0;
    }
  }
  .site-map-list-secondary {
    li {
      margin-top: 10px;
      font-size: @font-size-medium;
      a {
        color: @gray;
      }
    }
  }
}

.hr-stripe {
  background: url('@{assets_imgs}hr-stripe.png') center center no-repeat;
  width: 100%;
  height: 55px;
  margin: @grid-gutter-width 0;
}

aside {
  .panel-title {
    font-size: @font-size-large;
    line-height: 1.1;
    .clearfix;
  }

  

}

.panel-content-block .panel-heading,
.panel-default .panel-heading:not(.filter-heading) {
  color: @white;
  a { color: inherit; }
}
.panel-green {
  border-color: @giz-seagreen;
  border-radius: @border-radius-large;
}

.panel-body *:last-child{
  margin-bottom: 0;
}

.panel {
  
  background-color: transparent;
  &:not(.panel-green) {
    box-shadow: none;
  }

  .panel-heading a {
    &:hover {
      color: @giz-gray-100;
    }
  }

  .panel-body {
    padding: 10px;
    .panel-filter {
      margin-bottom: 0;
      
      
      .panel-body {
        padding-right: 0;
        padding-left: 0;
      }
    }
    
    h3 {
      border-bottom: 1px dotted @giz-gray-100;
      margin-top: 8px;
      padding-bottom: 8px;
      font-size: 25px;
      color: @giz-gray-100;
    }

    .h4 {
      margin-bottom: 5px;
      a {
        color: @giz-orange;
        font-family: @font-family-sans-serif;
      }
    }
    
  }
}
.panel-body .list-group:last-child {
  margin-bottom: 0;
}

.filter-heading {
  padding-left: 0;
  padding-right: 0;

  &.panel-heading {
    border-bottom: 1px dotted @giz-gray-100;
  }

  a {
    display: block;
    color: @giz-gray-100;
    position: relative;
    

    &, &:hover, &:focus {
      text-decoration: none;
    }

    &.collapsed .accordion-arrow {
      background: url('@{assets_imgs}prev-arrow.png') no-repeat;
    }

    .accordion-arrow {
      display: block;
      position: absolute;
      right: 0;
      top: ~'calc(50% - 5px)';            
      width: 10px;
      height: 10px;
      background: url('@{assets_imgs}next-arrow.png') no-repeat;
    }

  }
}
.filter-list,
.filter-link {
  border: 1px solid @gray-lighter;
  margin-bottom: 0;
  list-style-type: none;
  padding: 0;
}

.filter-list li,
.filter-link li {
  
  + li {
    border-top: 1px solid @gray-lighter;
  }

  a {
    
    padding: @padding-small-vertical @padding-small-horizontal;
    font-size: 14px;
    line-height: 20px;
    font-family: @headings-font-family;
    color: @giz-orange;
    display: block;
    text-decoration: none;
    font-family: @font-family-sans-serif;
  }

  a:hover {
    background-color: @gray-lighter;
    color: darken(@giz-orange, 15%);
  }

  &.active > a {
    color: @giz-seagreen;
    background-color: @giz-gray-60;
  }
}

.panel-group-filter {
  .panel + .panel {
    margin-top: 0;
  }
}

.panel-group-filter .panel-heading+.panel-collapse>.list-group, 
.panel-group-filter .panel-heading+.panel-collapse>.panel-body {
  border-top: 1px dotted @giz-gray-100;
  border-bottom: 1px dotted @giz-gray-100;
  margin-top: -1px;
}

.panel-content-block {
  padding: 0;
  margin: 0;
  .mb-1;
  .panel-heading {
    background: @giz-limegreen;
    border-radius: 10px 10px 0 0;    
  }

  .panel-title {
    font-size: 20px;
    line-height: 1.1;
    small {
      color: @white;
    }
  }

  .panel-body {
    padding: 15px;
    p:last-child {
      margin: 0;
    }
    ul:last-child {
      margin: 0;
    }
  }
  .panel-body, .list-group, .list-group-item:last-child {
    border-radius: 0 0 10px 0;
  }
  .list-group {
    border: 1px solid @giz-gray-60;
    border-top: none;
    a {
      font-size: 13px;
    }
  }
  .panel-body {
    border: 1px solid @giz-limegreen;
  }

  .dotted-border {
    margin: 0;
    background-position: 0 100%;
    padding-top: 20px;
  }

  &-promo {
    margin-bottom: @grid-gutter-width;
    .panel-title {
      small {
        color: @giz-seagreen-dark;
        display: block;
        font-weight: 900;
      }
      font-size: 18px;

    }

    .media-left {
      
      border-right: 1px solid @giz-limegreen;
      + .media-body {
        padding-left: 10px;
      }
    }

    .panel-body {
      border-width: 0 2px 2px;
    }

    .panel-body .media {
      margin: 0;
      padding-bottom: 0;
      border-width: 0;
    }

    .list-group {
      border-width: 0;
    }
  }
}
.panel-object {
  padding-bottom: 10px;
  margin: -10px -10px 0;
   display: block;
  
  
   
    &:hover, &:focus {
      text-decoration: none;
    }
  
  .img-responsive {
    margin: 0 auto;
  }
}

.list-group-sm {
  .list-group-item {
    padding: @padding-small-vertical @padding-small-horizontal;
  }
}

.text-number {
  font-size: 36px;
  font-family: @headings-font-family;
  font-weight: 900;
  line-height: 1.1;
  color: @giz-seagreen;
}

.list-group-columns {
  .clearfix;
    .list-group {
      .make-sm-column(6, 8px);
     
        .list-group-item:first-child,
        .list-group-item:last-child {
          border-radius: 0 !important;
        }
      
    }
}

.action a {
  color: @giz-seagreen-dark;

  &:hover {
    color: darken(@giz-seagreen-dark, 10%);
  }
}

.members-list-group {
  .list-group {
    
    .list-group-item {
      border: 1px solid @giz-limegreen;
    }
  }

  .action {
    float: right; 

    a:last-child {
      padding-right: 0;
    }
  }
}

.nav-pills.nav-stacked li {
  margin: 0;
  margin-left: 0;
  border-bottom: 1px solid @giz-gray-80;
  a {
    border-radius: 0;
    padding: 5px;
    font-size: 14px;
    line-height: 20px;
    font-family: @headings-font-family;
    color: @giz-orange;
    display: block;
    text-decoration: none;
    font-family: @font-family-sans-serif;
  }
  &.active > a, a:hover {
    background: @giz-gray-60;
    color: @giz-gray-100;

  }
}

.table {
  border: none;
  td {
    border: 1px solid @giz-gray-80;
    vertical-align: top;
    background-color: @white;
  }
  th {
    background: #dcdcdc;
    border-color: @giz-gray-80;
    &:not(.text-left) { text-align: center; }
  }
  ul {
    margin: 0;
  }
}

[data-table]:not(.dataTable) {
  display: none;
}
table {
  .table;
}

.table-borderless {
  > tbody > tr > td, 
  > tbody > tr > th, 
  > thead > tr> td,
  > thead > tr> th {
    border-width:  0;
  }
}

.table-mixin(@color) {
  border: none;
  tr:nth-child(odd) td {
    background-color: lighten(@color, 10%) !important;
    background-image: -webkit-linear-gradient(top, darken(@color, 5%), lighten(@color, 20%));
    background-image: linear-gradient(to bottom, darken(@color, 5%), lighten(@color, 20%));
    color: @white;
  }
  td {
    border: 1px solid @giz-gray-60;
  }
  tr {
    background: @white;
  }
  th {
    background-color: @color;
    background-image: -webkit-linear-gradient(bottom, @color, darken(@color, 10%));
    background-image: linear-gradient(to top, @color, darken(@color, 10%));
    border-color: @giz-gray-60;
    color: @white;
    font-size: @font-size-medium;
  }
}

.table-mixin-reverse(@color) {
  border: none;
  tr:nth-child(odd) td {
    background-color: lighten(@color, 10%);
    background-image: -webkit-linear-gradient(bottom, darken(@color, 5%), lighten(@color, 20%));
    background-image: linear-gradient(to top, darken(@color, 5%), lighten(@color, 20%));
    color: @white;
  }
  td {
    border: 1px solid @giz-gray-60;
  }
  tr {
    background: @white;
  }
  th {
    background-color: @color;
    background-image: -webkit-linear-gradient(top, lighten(@color, 3%), lighten(@color, 10%));
    background-image: linear-gradient(to bottom, lighten(@color, 3%), lighten(@color, 10%));
    border-color: @giz-gray-60;
    color: @white;
    font-size: @font-size-medium;
    text-align: center;
  }
}

.table-1 {
  .table-mixin(@giz-limegreen);
  th, tr:nth-child(odd) {
    color: @text-color;
  }
}

.table-2 {
  .table-mixin(@giz-seagreen);
}

.table-3 {
  .table-mixin(@giz-orange);
  tr:nth-child(odd) {
    color: @text-color;
  }
}

.table-4 {
  .table-mixin(@giz-gray-100);
}

.table-1-reverse {
  .table-mixin-reverse(@giz-limegreen);
  th, tr:nth-child(odd) {
    color: @text-color;
  }
}

.table-2-reverse {
  .table-mixin-reverse(@giz-seagreen);
}

.table-3-reverse {
  .table-mixin-reverse(@giz-orange);
  tr:nth-child(odd) {
    color: @text-color;
  }
}

.table-4-reverse {
  .table-mixin-reverse(@giz-gray-100);
}

/**	----------------------------------------
/**	3. PAGE SPECIFIC
/** Non-modular, non-repeatable elements
/**	----------------------------------------*/


.slideshow {
  .cycle-slideshow {
    width: 100%;

    .slide {
      max-width: 100%;
    }
    .slide, .image-holder, .video-holder, .cycle-slide {
      display: none;
    }
    .slide:first-child, .image-holder:first-child, .cycle-slide-active {
      display: block;
    }
  }

  .image-holder {
    .img-responsive {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .cycle-slide {
    width: 100%;
  }
}

.box-header {
  margin-bottom: 10px;
  .h2 {
    margin-top: 0;
  }
  .btn-primary {
    margin-top: 6px;
  }
}

.gallery {
  .cycle-overlay {
    padding: 5px 10px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 999;
    color: #fff;
  }
}

.cycle-pager .thumbnail {
  .opacity(0.7);
  margin-bottom: 10px;
  &:hover {
    .opacity(1);
    cursor: pointer;
  }
}

.cycle-pager-active .thumbnail {
  .opacity(1);
}

.glyphicon {
  .glyphicon;
}

.glyphicon-list {
  .glyphicon-list;
}

// Ensure we have reasonably tall pages no matter what.

#content {
  padding: 20px 0;
  .article-border {
    border-right: 1px solid @giz-gray-80;
  }
}

// Social Share icons
.social-icon {
  display: block;
  width: 34px;
  height: 34px;
  &.social-icon-twitter {
    background: url('@{assets_imgs}social-icon-twitter.png') no-repeat;
  }
  &.social-icon-facebook {
    background: url('@{assets_imgs}social-icon-facebook.png') no-repeat;
  }
  &.social-icon-youtube {
    background: url('@{assets_imgs}social-icon-facebook.png') no-repeat;
  }
}

// Social Share buttons

.page-heading .share-this {
  z-index: @zindex-tooltip;
  @media (min-width: @screen-sm) {
    &.sticky {
      position: fixed;
      width: auto !important;
      margin-left: -1 * (@grid-gutter-width + 10);
      
      
      
      .share-btn {
        display: block;
      }
    }
  }

  .share-btn {
    display: inline-block;
  }

  [data-share-default] {
    min-height: 37px;
  }
}



a.sharrre {
  color: @giz-limegreen;
  &:hover {
    color: darken(@giz-limegreen, 15%);
  }
}

.facebook-share,
.twitter-share {
  display: inline-block;
  &:hover, &:active, &:focus {
    text-decoration: none;
  }
}

.toc {
  border-left: 1px solid #dedee0;
  border-right: 1px solid #dedee0;
  &, ul {
    list-style: none;
    padding: 0;
  }

  ul {
    margin-bottom: 0;
  }

  a {
    padding: 5px 10px;
    display: block;
    border-bottom: 1px solid #dedee0;
    border-top: 1px solid #dedee0;
    margin-top: -1px;
    &, &:hover, &:active {
      text-decoration: none;
    }
  }

  
}

.toc-active a,
.toc li a:hover {
  background-color: darken(@giz-limegreen, 7.5%);
  color: @giz-seagreen;
}

.toc-h3 {
  a {
    padding-left: 20px;
  } 
}

.tocify-subheader {
  @media screen and (min-width: @screen-sm) {
    display: none;
  }
}


.tocify-header > li.tocify-item:not(:first-child),
.tocify-subheader li.tocify-item {
  a {
    padding-left: 25px !important;
    background-color: @giz-limegreen;
    color: #fff;
  }
  a:hover, &:focus, a.active {
    color: @giz-seagreen;
    background-color: darken(@giz-limegreen, 7.5%);
  }
}

.tocify-header > li:not(:first-child) {

  @media screen and (min-width: @screen-sm) {
    display: none;
  }
}

.tocify-header.active {
  > li.tocify-item:first-child {
    a, a:hover {
      background-color: darken(@giz-limegreen, 15%);
      color: #fff;
    }
  }
  li {
    display: block;
  }
}

.tocify-focus a {
  background-color: darken(@giz-limegreen, 7.5%);
  color: @giz-seagreen;
}

.tocify-subheader li.tocify-focus a {
  color: @giz-seagreen;
}



.panel-nav  {

 .navbar-toggle {
  padding: 0;
  margin: 5px 0 0;
  cursor: pointer;
 }

 .navbar-collapse {
  padding: 0;
  border-width: 0;
 }
}

.panel-default {
  .icon-bar {
    background-color: darken(@panel-default-heading-bg, 20%);
  }

  .navbar-toggle {
    &:hover, &:focus, &:active {
      .icon-bar {
        background-color: darken(@giz-seagreen, 20%);
      }
    }
  }
}

[data-dropdown] {
  > li {
    > a {
      padding-right: 18px !important;
      &:after {
        .fa-icon();
        content: @fa-var-chevron-down;
        position: absolute;
        top: ~"calc(50% - 6px)";
        right: 9px;
        font-size: 0.75em;
      }
    }
  }
  &.active > li > a:after {
    content: @fa-var-chevron-up;
  }
}


#tabs {
  &.member-tab {

    .nav-tabs {
      border-bottom: 1px solid #dddddd;
      li a {
        padding: 8px 10px;
        &.last-child {
          margin-right: 0;
        }
      }
    }

  }
  .nav-tabs {
    margin-bottom: 20px;
    li a {
      background: url('@{assets_imgs}nav-background.png') no-repeat;
      border: 1px solid @giz-gray-80;
      color: @giz-gray-100;
      height: 42px;
      font-weight: bold;
    }
    li.active a, li a:hover {
      background: @giz-seagreen;
      color: @white;
      border: 1px solid @giz-gray-60;
      border-bottom: 1px solid @giz-gray-60;
    }
  }
}

.input-group-addon {
  color: @giz-seagreen;
}

.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  width: 100%;
  direction: ltr;
  cursor: pointer;
}

.tab-layout {
  .nav-tabs {
    margin-bottom: 0 !important;
  }
  .tab-content {
    padding: 10px;
    border: 1px solid #dddddd;
    border-top: none;
    margin-bottom: @grid-gutter-width;

  }
}

.control-label + .instruction {
  margin-top: -4px;
}

.instruction {
  font-size: @font-size-small;
}

.btn-secondary {
  .button-variant(@brand-primary; transparent; @brand-primary);
}

label.error {
  color: red;
}

.fileUpload {
  position: relative;
  overflow: hidden;
  padding: 7px 20px;
  background: @giz-seagreen;
  &:hover {
    background: @giz-seagreen-dark;
  }
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  height: 39px;
}

.list-primary-info {
  li:first-child {
    width: 130px;
  }
  li {
    max-width: 210px;
  }
  li .h3 {
    color: @white;
    word-wrap: break-word;
  }
}

.list-secondary-info {
  li:first-child {
    width: 78px;
  }
  li {
    max-width: 533px;
  }
}

.list-primary-info, .list-secondary-info {
  li:first-child {
    text-align: right;
    vertical-align: top;
  }
}

.media-news {
  border-bottom: 0;
  padding: 0;
  margin: 0px;
}

.news .media-news .media-body {
  min-height: 171px;
}

[data-link] {
  cursor: pointer;
}

.breadcrumb-dot {
  list-style: none;
  li { display: inline-block; }
  li+li:before {
    content: '';
    background: url('@{assets_imgs}login-dot.jpg') no-repeat 4px center;
    padding: 0 9px;
  }
}

.form-horizontal em {
  color: @brand-danger;
}

.form-group-lg {
  margin-bottom: @grid-gutter-width;
}

/*
* Forms
*/

.btn-browse {
  position: relative;
  z-index: 1;
}

.fileinput-filename {
  display: none;
  padding: 8px 0;
}

.fileinput-preview.thumbnail {
  padding: 0;
  width: 200px;
}

.btn-danger-outline {
  .button-variant(@brand-danger, transparent, @brand-danger);
}
.btn-primary-outline {
  .button-variant(@brand-primary, transparent, @brand-primary);
}

.fileinput {
  position: relative;
}

.fileinput-preview {
  position: relative;
  display: block;
}

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists,
.fileinput-new .fileinput-remove {
  display: none;
}

.fileinput-exists .fileinput-filename {
  display: inline-block;
}

.fileinput-remove {
  opacity: 0.65;
  color: @gray-light;
  font-size: 14px;
  padding: 5px;

  &:hover {
    color: @brand-danger;
    opacity: 0.85;
  }
}

.close.fileinput-exists {
  float: none;
  font-size: @font-size-large;
  color: @brand-warning;
  opacity: 0.5;
}

.selectize-control.form-control {
  padding: 0;
  height: auto;
  border: none;
  background: none;
  box-shadow: none;
  border-radius: 0;
  line-height: 0;
}

.selectize-control.single .selectize-input {
  padding-right: 30px !important;
  &:after {
    right: 12px;
  }
}

.selectize-control .selectize-input {
  .item {
    font-size: small;
  }
}

.form-group-sm {
  .selectize-input {
    padding: 5px 10px;
    min-height: 30px;

    &:after {
      right: 12px !important;
    }
  }
}

.wygwam textarea {
  width: 100%;
  border: 1px solid @input-border;
}

.d-ib {
  display: inline-block;
}

.select {


  &.has-error {
    border-color: @brand-danger;
  }

 

  &:not(.select-default) {
    line-height: 0;
    border: 1px solid @input-border;
    border-radius: @input-border-radius;
    select {
      height: 34px;
      max-width: 100%;
      opacity: 0;
    }
  }

  .selectize-input {
    border-width: 0;
    box-shadow: none;
  }
}

.fw-500 {
  font-weight: 500;
}

 .select-default select {
  line-height: @line-height-base;
  position: relative;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input-icon {
  position: relative;
  &:after,
  &:before {
    .fa-icon();
    position: absolute;
    z-index:1;
    top: ~'calc(50% - 8px)';
    color: @gray;
  }
  
  &.left { 
    &:before {
      left: @padding-base-horizontal - 2;
    }
    > .dropdown,
    > .form-control,
    > select {
      padding-left: 2.2em;
    }
    
    &-auto:before { left: auto; }
  }
  
  &.right { 
    &:after {
      right: @padding-base-horizontal - 2;
    }
    > .dropdown,
    > .form-control,
    > select {
      padding-right: 2.2em;
    }
    &-auto:after { right: auto; }
  }
  
  > .dropdown,
  > .form-control {
    &:focus, &:active {
      outline: none;
    }
  }
}

.input-icon.search.left:before { content: @fa-var-search; }
.input-icon.search.right:after { content: @fa-var-search; }
.input-icon.search.right:after { content: @fa-var-search; }
.input-icon.select.left:before { content: @fa-var-angle-down; }
.input-icon.select.right:after { content: @fa-var-angle-down; }



.matrix-block,
.form-grouped {
  padding: 0 15px;
  margin-bottom: 10px;
  position: relative;

  .form-action {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid @gray-lighter;
  }

  .form-group-sm {
    .control-label {
      color: @gray-light;
    }
  }
}

.form-control-date {
  position: relative;
  &:after {
    .fa-icon();
    content: @fa-var-calendar;
    position: absolute;
    right: 12px;
    top: 10px;
  }

  .form-control {
    padding-right: 30px;
  }
}


.datepicker table tr td, 
.datepicker table tr th {
  border-radius: 0;
}

.datepicker table tr th {
  background-color: lighten(@giz-limegreen, 10%);
}

.datepicker .datepicker-switch:hover, 
.datepicker .next:hover, 
.datepicker .prev:hover, 
.datepicker tfoot tr th:hover {
   background-color: @giz-limegreen;
}

.datepicker table tr td.selected,
.datepicker table tr td.active.active.focus, 
.datepicker table tr td.active.active:focus, 
.datepicker table tr td.active.active:hover, 
.datepicker table tr td.active.highlighted.active.focus, 
.datepicker table tr td.active.highlighted.active:focus, 
.datepicker table tr td.active.highlighted.active:hover, 
.datepicker table tr td.active.highlighted:active.focus, 
.datepicker table tr td.active.highlighted:active:focus, 
.datepicker table tr td.active.highlighted:active:hover, 
.datepicker table tr td.active:active.focus, 
.datepicker table tr td.active:active:focus, 
.datepicker table tr td.active:active:hover {
  background-color: @brand-primary;
}


.datepicker-dropdown {
  padding: 5px;
  background-color: #ffffff;
  border-color: @gray-lighter;
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active:active, 
.datepicker table tr td.active:hover {
  background-color: @brand-primary;
}

.matrix-block {
  padding-right: 45px;
}

.form-control.has-error {
  border-color: @brand-danger;
}

.has-error .cke_chrome, .has-error textarea {
  border-color: @brand-danger !important;
}

.cke_chrome {
  border-color: @input-border !important;
}


.form-group-nested {
  
  .make-row(15px);

  [class*='col-'] {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }

  + .parsley-errors-list {
    position: relative;
    top: -15px;
  }
}

.required:not(.help-block):after {
  content: ' *';
  color: @brand-danger;
  
}

.help-block.required:before {
  content: '* ';
  color: @brand-danger;
}

.checkbox-inline, .checkbox label,
.radio-inline, .radio label {
  border: 1px solid transparent;
  &:not(.option) {
    border-color: #ddd;
    background-color: #fdfdfd;
  }
  padding: 2px 5px 2px 25px;
  border-radius: @border-radius-base;
  &.option {
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding: 5px 10px;
    input {
      opacity: 0;
    }
  }
}

&.option {
  border-color: #dedede;
  background-color: #fefefe;
}

.warning .option {
  border-color: @brand-success;
}
.success .option {
  border-color: @brand-success;
  background-color: @brand-success;
  color: #fff;
}

.danger .option {
  border-color: @brand-danger;
  background-color: @brand-danger;
  color: #fff;
}

.answer:after {
  position: absolute;
  right: 10px;
  top: ~'calc(50% - 7px)';
  .fa-icon();
}

.answer.warning:after {
  color: @brand-success;
}

.answer.success:after,
.answer.danger:after {
  color: #fff;
}

.answer.warning:after,
.answer.success:after {
  content: @fa-var-check;
  
}
.answer.danger:after {
  content: @fa-var-times;
}

.question {
  .clearfix;
}

.question-number {
  font-size: 200%;
  line-height: 1;

  display: inline-block;


  &:after {
    content: '.';
    display: inline-block;
  }
}

.question-title {
  font-family: @font-family-base;
  padding-left: 45px;
  display: block;
  font-weight: normal;

  font-size: 16px;

  .help-block {
    margin: 0;
    font-size: @font-size-small;
    &:before {
      .fa-icon();
      font-size: 0.75em;
      padding-right: 2px;
    }

    &.block-open:before {
      content: @fa-var-chevron-down;
      vertical-align: 15%;
    }

    &.block-close {
      display: none;
      &:before { content: @fa-var-chevron-up; vertical-align: 25%; }
    }
  }

  &:not(.collapsed) {
    .block-close {
      display: block;
    }
    .block-open {
      display: none;
    }
  }

  .question-number {
    margin-left: -50px;
    width: 45px;
    text-align: right;
  }

  color: @text-color;

  &:hover, &:focus, &:active {
    text-decoration: none;
  }

  &.medium {
    padding-left: 65px;
    font-weight: 300;

    .question-number {
      margin-left: -65px;
      width: 55px;
    }
  }
}

.question-body {
    padding-left:45px;

    &.medium {
      padding-left: 65px;
    }
  }

  .question-object {
    padding-bottom: @panel-body-padding;
    > img {
      .center-block;
    }
  }

  @media (min-width: @screen-sm) {
    .question-object {
      position: relative;
      float: left;
      width: percentage(4/12);
      left: percentage(8/12);

      + .question-body {
        position: relative;
        float: left;
        width: percentage(8/12);
        right: percentage(4/12);
        padding-right: @grid-gutter-width;;
      }
    }

    .question-body.medium {
      padding-left: 64px + @grid-gutter-width;
    }
  }



/*-----*/

.call-to-action {
  display: block;
  text-align: left;
  
  .media-heading {
    font-size: 26px;
    line-height: 30px;
    margin: 0;
  }

  .media-summary {
    font-size: 12px;
    color: @text-color;
    &:hover {
      color: darken(@text-color, 20%);
    }
  }

  .media {
    padding-left: 100px;
    min-height: 88px;
  }

  .cta-item {
    position: absolute;
    left: 0px;
    top: 0;
    width: 88px;
    height: 88px;
    &:before {
      width: 0;
      height: 0;
      visibility: 0;
    }
    &.cta-1 {
      background: url('@{assets_imgs}understand-static.gif') center center;
      &:before {
        background: url('@{assets_imgs}Understand_01.gif') center center;
      }
    }
    &.cta-2 {
      &:before {
        background: url('@{assets_imgs}Inspire_01.gif') center center;
      }
      background: url('@{assets_imgs}inspire-static.gif') center center;
    }
    &.cta-3 {
      &:before {
        background: url('@{assets_imgs}Tools.gif') center center;
      }
      background: url('@{assets_imgs}tools-static.gif') center center;
    }
    &.cta-4 {
      &:before {
        background: url('@{assets_imgs}connect_01.gif') center center;
      }
      background: url('@{assets_imgs}connect-static.gif') center center;
    }
  }

  &:hover .cta-item {
    &.cta-1 {
      background: url('@{assets_imgs}Understand_01.gif') center center;
    }
    &.cta-2 {
      background: url('@{assets_imgs}Inspire_01.gif') center center;
    }
    &.cta-3 {
      background: url('@{assets_imgs}Tools.gif') center center;
    }
    &.cta-4 {
      background: url('@{assets_imgs}connect_01.gif') center center;
    }
  }

}

.no-margin-top {
  margin-top: 0;
}

#top-link {
  display: none;
  right: @grid-gutter-width / 2;
  .fa-stack {
    line-height: 1.8em;
  }
  position: fixed;
  bottom: 15px;
}

.fixed {
  position: fixed;
  right: 0;
  z-index: 1000;
}

.fixed-bottom {
  right: 1%;
  bottom: 2%;
}

.dotted-border {
  background: url("@{assets_imgs}dotted-border.jpg") repeat-x;
  display: block;
  width: 100%;
  margin: 15px 0;
  height: 1px;
}



#page-slideshow {
  margin-bottom: 25px;
  .cycle-slideshow {
    margin-bottom: 5px;
  }
  .thumbnail {
    border: 1px solid @giz-gray-60;
    border-radius: 0;
    margin-bottom: 5px;
  }
}

.form-registration {
  h2 {
    margin-top: 0;
  }
}

.feature-quote {
  font-size: 14px;
  color: @giz-limegreen;
  .quote-body,
  h1 {
    color: @giz-seagreen;
    font-size: 20px;
    line-height: 1.1em;
    font-family: @headings-font-family;
    margin-bottom: 10px;
    font-weight: 600;
  }
}

.event-meta {
  font-size: @font-size-small;
  color: @gray;
  font-weight: 600;
}

#map.tab-pane {
  display: block !important;
  visibility: hidden;
  height: 1px;
}

#map.active {
  visibility: visible;
  height: 100%;
}

#map-div {
  padding-bottom: percentage(9/16);
  margin-bottom: 30px;
  .gm-style-iw {
    max-width: 140px;
  }
}

.resources-button {
  background: url('@{assets_imgs}resources-button-image.png') no-repeat;
  width: 140px;
  height: 166px;
  &:hover {
    .opacity(0.8);
  }
}

.modal-dialog {
  max-width: 960px;
}

.resource-list {
  li {
    margin: 10px 0;
    .list-group-item {
      word-break: break-all;
      border-color: @giz-gray-80;
    }
  }
  .fa {
    margin-right: 5px;
  }
}

input[type="checkbox"] {
  margin-right: 10px;
}

.home .modal-dialog {
  max-width: 720px;
  padding-top: 12%;
}

.file-holder {
  border: 1px solid @giz-gray-80;
  margin-bottom: 10px;
  padding: 10px;
  label {
    display: block;
  }
  input, select {
    margin-bottom: 5px;
  }
}


.resource-list-group {
  border: none !important;
  .list-group-item {
    padding: 10px;
    border-radius: 0;
    border: 1px solid #dddddd;
    font-size: 12px;
    font-weight: 600;
  }
  .list-group-item:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .fa {
    font-size: 18px;
    margin-right: 5px;
  }
}


// Navigation

.navbar-menu {
  @media (max-width: @grid-float-breakpoint) {
    position: absolute;
    z-index: 3;
    left: 0px;
    right: 0px;
    box-shadow: 0 7px 12px rgba(0,0,0,.175);
    .navbar-nav {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.navbar-item + .navbar-item:before {
  border-left: 1px solid;
  position: absolute;
  left: 0;
  top: 15px;
  bottom: 15px;
  
  @media (min-width: @screen-md) {
    content: "";
  }
}

.navbar-default .navbar-item:before {
  border-left-color: @navbar-default-link-color;
}

#header-banner {
  position: relative;
  .header-control {
    position: absolute;
    z-index: 999;
    .control {
      text-decoration: none;
      position: absolute;
      width: 13px;
      height: 22px;
      font-size: 70px;
      line-height: 1;
      top: -20px;
      color: @giz-gray-60;
      &:hover {
        color: darken(@giz-gray-80, 15%);
      }
    }
  }
}

.header-right {
   float: right;
  padding-bottom: 10px;
  @media (min-width: @screen-phone) {
   
    margin-top: @grid-gutter-width;
  }
}

.img-radius-top {
  border-radius: 10px 10px 0 0;
}

.media-heading a {
  &, &:hover, &:focus {
    text-decoration: none;
  }
}

.media-slide {
  display: none;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

.media-caption {
  font-family: @font-family-alt;
  color: @white;
  font-weight: 400;
  background: @giz-limegreen;
  border-radius: 0 0 10px 0;
  position: relative;
  padding: @padding-base-vertical;
  text-align: center;
  display: none;
  line-height: @line-height-large;
  &:before {
    position: absolute;
    left: 0;
    top: -15px;
    height: 15px;
    content: '';
    background: url('@{assets_imgs}box-arrow.png') no-repeat center;
    width: 100%;
  }

  @media screen and (min-width: @screen-sm) {
    display: block;
  }
}

.media-reveal .media-slide {
  @media screen and (max-width: @screen-sm) {
    position: static;
    display: block;
    background-color: lighten(#e1e0e0, 25%);
    .media-slide-link {
      border: 1px solid #e1e0e0;
      border-radius: 9px 9px 0 0;
    }
  }
}

.media-slide-link,
.media-slide {
  position: absolute;
  left: 0;
  .square(100%);
}

.media-slide-link {
  top: 0;
}

.media-slide-link,
.media-slide-link:hover, .media-slide-link:focus, .media-slide-link:active {
  text-decoration: none;
}

.media-slide-up {
  position: relative;
  border-width: 0;

  .media-content {
    font-size: 13px;
    font-weight: 600;
    color: @text-color;
    padding: @grid-gutter-width / 2;
  }

  .media-content-alt {
    font-size: @font-size-base;
    .media-meta {
      margin-bottom: 10px;
    }
    padding: @grid-gutter-width;
  }

  @media (min-width: @screen-sm) {
    &:hover .media-slide {
      display: block;
    }
  }

  .media-title {
    display: table;
  }

  .media-object {
    position: relative;
    overflow: hidden;
  }

  

  .title-sizer {
    padding: 10px;
    display: table-cell;
    vertical-align: middle;
    width: 1000px;
    @media (min-width: @screen-sm) {
      text-align: center;
      padding: 5px;
    }
  }

  .media-summary .media-content {
    border: 1px solid #e1e0e0;
    border-top-width: 0;
  }

  .read-more {
    font-weight: 400;
    color: @link-color;
    &:hover {
      color: @link-hover-color;
    }
  }

  .meta,
  .province-label {
    display: block;
    margin-bottom: 10px;
    font-size: 11px;
    font-weight: 600;
    line-height: 1;
    color: @giz-seagreen;
  }

  .h3 {
    margin: 0 0 5px 0;
    font-family: @font-family-sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: @giz-seagreen;
    > a {
      color: inherit;
    }
  }

  .btn-edit-this {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #fff;
    padding: 0 3px;
  }
}

.box-arrow {
  margin-bottom: 0;
  font-family: @font-family-alt;
  font-weight: 400;
  --bg-color:  @giz-limegreen;
  background-color: var(--bg-color);
  position: relative;

  a {
    position: relative;
    z-index: 2;
  }
 
  @media (min-width: @screen-sm) {
    font-size: @font-size-medium;
    line-height: 18px;
    border-radius: 0 0 10px 0;
    &:before {
      position: absolute;
      left: 50%;
      bottom: 100%;
      transform: translateX(-50%);
      content: '';        
      width: 0; 
      height: 0; 
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 15px solid var(--bg-color);
    }
  }
}

@media (max-width: @screen-sm) {
  .bd--b-xs {
    border: 1px solid #e1e0e0;
    border-bottom-width: 0;
  }
}

.vertical-media {
  display: block;
  .vertical-img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .media-title {
    display: table;
    background: @giz-limegreen;
    margin: 0;
    font-family: @font-family-alt;
    font-size: @font-size-medium;
    line-height: 18px;
    color: @white;
    font-weight: 400;
  }
  .media-body {
    padding: 10px;
    border-bottom: 1px solid #e1e0e0;
    border-left: 1px solid #e1e0e0;
    border-right: 1px solid #e1e0e0;
    h3:first-child {
      margin-top: 0;
    }
    .insight-label {
      display: block;
      position: absolute;
      // height: 37px;
      padding-left: 8px;
      padding-top: 5px;
      z-index: 999;
      .insight-label-content {
        background-color: #fcb034;
        float: left;
        padding: 5px 5px 5px 10px;
        max-width: 120px;
        height: 28px;
        max-height: 28px;
        overflow: hidden;
        color: @white;
        font-size: 10px;
        font-weight: 700;
        line-height: 18px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      .insight-label-overflow {
        background: url('@{assets_imgs}inspired-label.png') no-repeat;
        float: left;
        width: 11px;
        height: 37px;
      }
    }
    .h3 {
      margin: 0;
      margin-bottom: 5px;
      font-family: @font-family-sans-serif;
      font-size: 18px;
      font-weight: 600;
      color: @giz-seagreen;
    }
    .province-label {
      display: block;
      margin-bottom: 10px;
      font-size: 11px;
      font-weight: 600;
      line-height: 1;
      color: @giz-seagreen;
    }
    .read-more {
      color: @link-color;
      &:hover {
        color: @link-hover-color;
      }
    }
    p {
      font-size: 13px;
      font-weight: 600;
      color: @text-color;
    }
  }
}

.subscription-box {
  position: relative;
  overflow: hidden;
  .spinner-img {
    width: 20px;
    margin-bottom: 0 !important;
  }
}

#validationfield {
  position: absolute;
  left: -100%;
}

.close {
  z-index: 999;
}

.media-profile {
  padding: 20px;
  border-radius: 5px;
  background: #c2c86c;
  a {
    color: @giz-seagreen !important;
    &:hover {
      color: @giz-seagreen-dark !important;
    }
  }
  .h3 {
    font-size: 20px;
  }
}

.form-section {
  .clearfix;
  padding-bottom: @grid-gutter-width/3;
  margin: 0 0 @grid-gutter-width/2;
  border-bottom: 1px solid @gray-lighter;
}

.btn-dashboard {
   background-color: #c2c86c;
   color: #fff;
   border-radius: 10px 10px 0 0;
  padding: 7px 15px;
  cursor: pointer;
  text-align: center;
  font-family: @headings-font-family;
  font-size: 1.5715em;
  font-weight: 700;
  line-height: @line-height-large;

    &:hover, &:focus {
      background-color: darken(#c2c86c, 5%);
    }
    

    &.collapsed {
      .toggle-close {
        display: none;
      }
    }

    &:not(.collapsed) {
      .toggle-open {
        display: none;
      }
    }
}

.dashboard {
  &.in {
    border-bottom: 1px solid @giz-gray-80;
  }
}

#welcome-dashboard {
  // 
  .mb-1;
  .panel .panel-body {
    .btn-primary {
      font-weight: 700;
      border: 1px solid @giz-limegreen;
      background: @white;
      color: @text-color;
      border-radius: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      &:hover {
        background: @giz-limegreen;
        color: @white;
      }
    }
    > .list-group {
      border: none;
    }
  }
  .dashboard-main-hidden {
    background-color: #c2c86c;
    &:hover, &:focus {
      background-color: darken(#c2c86c, 5%);
    }
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 7px 15px;
    cursor: pointer;
    text-align: center;
    .panel-title {
      color: @white;
      font-size: 22px;
      line-height: 28px;
    }
    .fa {
      color: @white;
      font-size: 24px;
      line-height: 1;
      &:before {
        content: "\f107";
      }
    }
    &.open .fa {
      &:before {
        content: "\f106";
      }
    }
  }
  .dashboard-main {
    display: none;
    padding-top: 15px;
    .panel-body {
      background-color: @white;
    }
  }
}

.element-container {
  h3 {
    color: @text-color;
  }
  .title {
    margin: 0;
    margin-bottom: 20px;
  }
 
}

.label-primary {
  &:not(.fz-i) {
    background-color: @giz-limegreen;
    display: inline-block;
    margin-bottom: 3px;
    font-size: 14px;
  }
}



.logo-box {
  text-align: center;
  
  img {
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .media-object {
    padding: 10px;
  }
}

.logo-boxes {
  .clearfix();
  margin-bottom: 15px;

  .logo-box {
    border-color: @giz-seagreen;
    border-bottom: 1px solid @giz-seagreen;
    width: 50%;
    float: left;
    position: relative;
  }
}


.logo-boxes-odd {
  .logo-box:last-child {
    width: 100%;
    float: none;
  }
}

/*------------------------------*/

.sidebar-details-box {
  border: 1px solid @giz-seagreen;
  padding: 10px;
  border-radius: 5px;

  a {
    word-wrap: break-word;
    font-weight: 600;
  }
  .h4 {
    font-size: 20px;
    color: @giz-seagreen;
    margin: 0;
  }
}

.list-social > li > a {
  font-size: @font-size-large;
}

.profile-social-links {
  margin: 5px 0;
  .fa {
    font-size: 20px;
  }
  li {
    padding-right: 15px;
  }
  li:first-child {
    padding-left: 0;
  }
}

.panel-content-block .list-group {
  a.edit-button {
    margin-top: 5px;
    padding: 3px 7px;
  }

  .list-group-item:last-child {
    border-radius: 0 0 10px 0;
  }
}

.edit-button {
  .btn;
  .btn-sm;
  .btn-primary;
  padding: 3px;
}

.segment-2-create-pop-up {
  #header, .breadcrumb-container, .nav-container {
    display: none;
    visibility: hidden;
  }
}

.hide-blog-image,
.spinner-svg {
  display: none;
}

.btn-spinner {
  padding: 5px 0;
  height: 100%;
  .spinner-svg {
    display: block;
    margin: 5px 0;
  }
}

.resource-uploader {
  .matrix-text {
    display: none !important;
    visibility: hidden;
  }
}

.navbar-form {
  .search-trigger {
    display: none;
  }
  .input-group-btn .btn {
    background-color: @giz-seagreen;
    color: @white;
  }
}

.collapse-icon {
  position: relative;
  padding-right: 24px !important;
  &:after {
    .fa-icon();
    content: @fa-var-caret-down;
    position: absolute;
    top: ~"calc(50% - 7px)";
    right: 15px;

    @media (min-width: @grid-float-breakpoint) {
      right: 10px;
    }
  }
}

.navbar-item > [data-toggle="dropdown"] {
  .collapse-icon();
}

.vertical-media-container {
  margin: 0 50px;
}

.slick-carousel {
  .slick-prev {
    left: 10px;
  }
  .slick-next {
    right: 10px;
  }
  padding: 0 @grid-gutter-width;

  @media (min-width: @screen-lg) {
    .slick-prev {
      left: -15px;
    }

    .slick-next {
      right: -15px;
    }

    padding: 0;
  }
}
.list-group-slide {
  margin-bottom: 0;
}
.slick-slider .list-group-slide .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
  &:last-child {
    border-bottom-width: 0;
    border-radius: 0 0 10px;
  }
}

.slick-carousel.slick-slider,
.slick-carousel.slick-slider .media-slide-up {
  margin-bottom: 0;
}

.slider-container {
  position: relative;
}

.slider-blog {
  .slick-list,
  .slick-track,
  .media-object,
  .media-slide-up {
    height: 100%;
    margin-bottom: 0;
  }

  @media (max-width: @screen-md) {
    margin-left: @grid-gutter-width;
    margin-right: @grid-gutter-width;
    .slick-prev {
      left: -(@grid-gutter-width + 5);
    }

    .slick-next {
      right: -(@grid-gutter-width + 5);
    }
  }
}

.slick-list.draggable {
  outline: none;
  box-shadow: none;
}

.slick-slideshow,
.slider-blog {
  .slick-slide {
    width: 100%;
    outline: none;

  }
  .slick-slide:first-child {
    display: block;
  }
}

.slick-slide-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  @media (max-width: @screen-sm) {
    background-image: none !important;
  }
}

.slide-image-horizontal {
  img {
    margin: auto;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }
}

.slide-image-vertical {
  img {
    margin: auto;
    width: auto;
    height: 100%;
    vertical-align: middle;
  }
}

.slick-slider-bg {
  .slide-image {
    position: relative;
    background: no-repeat 50%;
    background-size: contain;

    &.slide-image-3by2 {
      padding-bottom: percentage(2/3);
    }

    &.slide-image-1by1 {
      padding-bottom: 100%;
    }

    @media print {
      padding-bottom: 0 !important;
      height: 100%;
    }


    .slide-inner {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    
  }

  &:not(.slick-slider-thumbnail) {
    .slide {

      &:not(.slick-slide):not(:first-child) {
        display: none;
      }

      @media print {
        &:not(.slick-active) {
          height:  1px !important;
        }
      }

      border: 1px solid @gray-lighter;
      padding: 4px;
    }
  }

  .caption {
    
    margin-top: 10px;
    padding: 5px;
     border-top: 1px solid @gray-lighter;

    font-size: @font-size-small;
    *:last-child {
      margin-bottom: 0;
    }
  }

}

.slick-slider-thumbnail {
  .clearfix;
  margin-left: -6px;
  margin-right: -6px;
  .slide-image {
    background-size: cover;
  }
  .slide {
    width: 25%;
    position: relative;
    float: left;
    padding: 6px;
    cursor: pointer;

    @media (min-width: @screen-sm) {
      width: percentage(2/12);
    }

    .slide-inner {
      border: 1px solid @gray-lighter;
      padding: 4px;
    }
  }

  .slick-page-nav-active .slide-inner {
    border-color: @giz-seagreen;
  }
}

[data-unique] {
  clear: both;
}

.slider-blog-nav {
  display: none;
  border: 2px solid #ededed;
}

.slick-nav-item {
  width: 100%;
  padding: @grid-gutter-width / 2;
  cursor: pointer;
  position: relative;
  &:not(:last-child) {
    border-bottom: 1px dotted @giz-gray-80;
  }
  &.slick-page-nav-active,
  &.slick-nav-item-active {
    background-color: #ededed;
    &:before {
      right: 100%;
      top: 50%;
      border: 20px solid transparent;
      border-right-color: #ededed;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      margin-top: -20px;
      margin-right: 0;
    }
  }
}

.slick-prev, .slick-next {
  width: auto;
  height: auto;
}

.slick-prev:before, .slick-next:before {
  color: @giz-seagreen;
  font-size: 28px;
}

.slide-image-video {
  iframe {
    position: absolute;
    .square(100%);
  }
}

.slide-player {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  .square(100%);

  &.playing {
    z-index: -1;
  }
}

.slick-slide {
  .media-image {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding-bottom: percentage(2/3);
  }
}

.slick-slideshow {

  .slick-content {
    bottom: 0;
    width: 100%;
    padding: @grid-gutter-width;
  
    @media (min-width: @screen-lg) {
      padding-left: percentage(3/12);
    }
  }

  .slideshow-image {
    background-size: cover;
    background-position: 50%;
  }

  
  .slick-dots {
    margin-bottom: 0;
    bottom: @grid-gutter-width;
    text-align: left;
    width: percentage(5/12);
    li:first-child {
      margin-left: @grid-gutter-width;
      @media (min-width: @screen-lg) {
        margin-left: percentage(3/12);
      }
    }

    li button {
      &:before {
        width: 18px;
        height: 18px;
        opacity: 1;
        content: "";
        border-radius: 50%;
        border: 2px solid @white;;

      }
    }
    li.slick-active button:before { background-color: @white; }
  }

  @media (min-width: @screen-sm) {
    .slideshow-object,
    .slideshow-body {
      float: left;
    }

    .slideshow-object {
      width: percentage(7/12);
      position: relative;
      margin-left: percentage(5/12);
    }

    .slideshow-body {
      position: absolute;
      height: 100%;
      width: percentage(5/12);
    }
  }
}

.block	{ display: block !important; }
.inline-block	{ display: inline-block !important; }
.inline	{ display: inline !important; }
.flex	{ display: flex !important; }
.inline-flex	{ display: inline-flex !important; }
.table	{ display: table !important; }
.table-caption	{ display: table-caption !important; }
.table-cell	{ display: table-cell !important; }
.table-column	{ display: table-column !important; }
.table-row	{ display: table-row !important; }
.flow-root	{ display: flow-root !important; }
.grid	{ display: grid !important; }
.inline-grid	{ display: inline-grid !important; }
.contents	{ display: contents !important; }
.hidden	{ display: none !important; }

@media (min-width: @screen-xs) {
  .block-xs { display: block !important; }
}

.flex-wrap { flex-wrap: wrap; }
.flex-wrap-reverse { flex-wrap: wrap-reverse; }
.flex-nowrap { flex-wrap: nowrap; }

.flex-row	{ flex-direction: row; }
.flex-row-reverse	{ flex-direction: row-reverse; }
.flex-col	{ flex-direction: column; }
.flex-col-reverse	{ flex-direction: column-reverse; }

.flex-1	{ flex: 1 1 0%; }
.flex-auto	{ flex: 1 1 auto; }
.flex-initial	{ flex: 0 1 auto; }
.flex-none	{ flex: none; }

@media (min-width: @screen-xs) {
  .flex-xs-row {
    flex-direction: row;
  }
}
@media (min-width: @screen-sm) {
  .flex-sm-row {
    flex-direction: row;
  }
}
.order-0 { order: 0; }
@media (min-width: @screen-xs) {
  .order-xs-first {
    order: -9999;
  }
}
@media (min-width: @screen-sm) {
  .order-sm-first {
    order: -9999;
  }
}

.items-start { align-items: flex-start !important; }
.items-end { align-items: flex-end !important; }
.items-center { align-items: center !important; }
.items-baseline { align-items: baseline !important; }
.items-stretch { align-items: stretch !important; }
@media  (max-width: @screen-xs) {
  .flex [class*='col-'] {
    width: 100%;
  }
}

.h-auto	{ height: auto !important; }
.h-px	{ height: 1px !important; }
.h-full	{ height: 100% !important; }
.h-screen	{ height: 100vh !important; }

.w-auto	{ width: auto !important; }
.w-px	{ width: 1px !important; }
.w-full	{ width: 100% !important; }
.w-screen	{ width: 100vh !important; }

@media (min-width: @screen-md) {
  .w-md-auto	{ width: auto !important; }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

@media (min-width: @screen-md) {
  .slider-blog,
  .slider-blog-nav {
    float: left;
  }

  .slider-blog {
    width: percentage(7/12);
    height: 100%;
    position: absolute;
    border: 2px solid #ededed;
    border-right-width: 0;
  }

  .slider-blog-nav {
    display: block;
    position: relative;
    width: percentage(5/12);
    margin-left: percentage(7/12);
  }
}



@media (min-width: @screen-md) {
  .my-affix.sticky {
    position: fixed !important;
    top: 20px;
    z-index: 100;

  }

  .my-affix.static {
    max-width: 100%;
  }
}

/* Small devices (tablets, 768px and less) */
@media (max-width: @screen-xs-max) {
  #header-banner {
    display: none;
    visibility: hidden;
  }

  .mobile-header-image {
    display: block;
    visibility: visible;
  }

  .news .media-object {
    display: none;
  }

  .mobile-divider {
    border-top: 1px solid @giz-gray-60;
    margin-top: 15px;
    padding-top: 15px;
    .form-group {
      margin-bottom: 0;
    }
  }

  .form-case-study {
    hr {
      margin: 10px 0;
    }
  }

  .members, .organisation-entry {
    .media-object {
      float: none !important;
      margin-bottom: 20px;
    }
  }

  #equalize-listing {
    .col-xs-6 {
      min-height: 200px !important;
    }
  }

  .video-holder {
    position: relative !important;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }

  .video-holder iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .nav-tabs > li {
    float: none;
  }
}

@media (max-width: @screen-sm-max) {

  .vertical-media {
    .vertical-img {
      width: 100% !important;
      height: 100% !important;
    }
    .media-title {
      font-size: 20px;
      line-height: 26px;
      padding: 10px;
    }
    .media-body {
      .insight-label {
        bottom: 0px;
        right: 7px;
      }
    }
  }

  .home, .be-inspired {
    .vertical-media {
      .media-title {
        display: none;
      }
    }
  }

  .utility-row {
    span.dotted-border {
      display: none;
    }
  }

  #content .article-border {
    border: none;
  }

}

.file-uploads-holder {
  thead {
    display: none;
  }
  td.matrix {
    display: block;
    clear: both;
    float: left;
    width: 100%;
  }

}

.cke_dialog_ui_vbox_child .cke_dialog_ui_button, #cke_target_334, #cke_advanced_386, #cke_235_select option[value='anchor'] {
  display: none;
  visibility: hidden;
}

.sidebar-media-img {
  display: none;
}

.parsley-errors-list {
  list-style: none;
  color: @brand-danger;
  font-weight: 400;
  padding: 0;
  margin-top: 5px;
  font-size: @font-size-small;
}

textarea.parsley-error, input.parsley-error, .parsley-error input[type="checkbox"], .parsley-error + .bootstrap-select .btn, .parsley-errors-list + .bootstrap-select .btn {
  border: 2px solid @brand-danger;
}

.desc_hide {
  display: none;
}

.blog-featured {
  margin: 0 20px;

  .blog-image {
    // padding: 0 50px;
    .image-holder {
      // position: absolute;
      img {
        border: 2px solid #ededed;
      }
    }
    .blog-image-overlay {
      border: 2px solid #ededed;
      padding: 20px;
      background: rgba(255, 255, 255, 0.9);
      // position: absolute;
      // width: 100%;
      // height: 100%;
      z-index: 999;
      color: @text-color;
      a {
        color: @text-color;
      }
      .media-meta, .h3 {
        color: #277770;
      }
      p {
        font-weight: 600;
      }
    }
  }

}

.b-r-6 {
  border-width: 2px;
}

.media-box {
  .media-img {
    margin-bottom: 10px;
  }
  .media-body {
    clear: both;
  }
}

.media-bd {
  border: 1px solid @gray-lighter;
}

[class*="media-img-"].media-img {
  position: relative;
  background: #fdfdfd no-repeat center center;
  display: block;
  background-size: 100% auto;
  // border: 1px solid @giz-seagreen;

  > img {
    position: absolute;
    .square(100%);
  }

  + .media-body {
    padding: @padding-base-vertical;
  }

  &-1by1 {
    padding-bottom: 100%;
  }

  &-3by2 {
    padding-bottom: percentage(2/3);
  }

  &-7by5 {
    padding-bottom: percentage(5/7);
  }

  &-16by9 {
    padding-bottom: percentage(9/16);
  }
}


@media (min-width: @screen-sm) and (max-width: @screen-md) {
  .clear-sm {
    clear: left;
  }
}

@media (min-width: @screen-md) {
  .clear-md {
    clear: left;
  }
}

@media  (min-width: @screen-xs) and (max-width: @screen-xs-max) {
  .col-xs-sm-8 {
    float: left;
    width: percentage((8 / @grid-columns));
  }

  .col-xs-sm-6 {
    float: left;
    width: percentage((6 / @grid-columns));
  }

  .col-xs-sm-3 {
    float: left;
    width: percentage((3 / @grid-columns));
  }

  .col-xs-sm-4 {
    float: left;
    width: percentage((4 / @grid-columns));
  }

}

/* Small devices (tablets, 768px and up)  */
@media (min-width: @screen-sm) {
  .video-container {
    &.slide-video {
      padding-bottom: 0;
      height: 450px;
    }
  }

  .container-fluid .row {
    margin: 0;
  }

  .sidebar-media-img {
    display: block;
  }

  // Video Banner
  #header-banner .video-promo {
    background: #134a44;
    border: 2px solid #c6c6c6;
    position: absolute;
    top: 20px;
    left: 650px;
    display: block;
    width: 140px;
    height: 130px;
    padding: 10px;
    color: @white;
    font-size: 14px;
    font-family: @font-family-alt;
    text-align: center;
    cursor: pointer;
    z-index: 999;
    .video-button {
      margin-bottom: 10px;
      width: 91px;
      height: 51px;
      background: url('@{assets_imgs}video-button.png') no-repeat;
      -webkit-border-radius: 10px;
      -webkit-border-bottom-left-radius: 0;
      -moz-border-radius: 10px;
      -moz-border-radius-bottom-left: 0;
      border-radius: 10px;
      border-bottom-left-radius: 0;
    }
  }

  .vertical-img {
    max-height: 246px;
  }

  .media-special-img {
    height: 185px;
    width: 185px;
  }

  .panel-content-block, .quote-block {
    &.float-right {
      float: right;
      margin: 0 0 @line-height-computed @line-height-computed;
      clear: right; // This ensures images don't 'bump' into each other
    }
    &.float-left {
      float: left;
      margin: 0 @line-height-computed @line-height-computed 0;
      clear: left; // This ensures images don't 'bump' into each other
    }
    &.float-center {
      float: none;
      margin: 20px auto;
    }
  }



  #footer {
    #footer-bar {
      .h2 {
        margin-top: 0;
      }
      .special-border-row {
        border-bottom: none;
        border-right: 1px solid @giz-gray-60;
        margin: 0;
        padding-bottom: 0;
      }
    }
  }

  .slider-nav {
    margin: 0;
  }

  .special-border-row {
    border-right: 1px solid @giz-gray-80;
  }

  .slider-for {
    margin-bottom: 0;
  }

  .media-box {
    .media-img {
      margin-bottom: 0;
    }
    .media-body {
      clear: none;
    }
  }
}

@media (max-width: 991px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md) {

  

  .slider-for {
    .slick-next, .slick-prev {
      display: none !important;
      visibility: hidden;
    }
  }

  .navbar-collapse {
    padding: 0;
  }


  #tabs.member-tab {
    .nav-tabs {
      width: 852px;
    }
  }

  #tabs .nav-tabs {
    a {
      padding: 10px 7.5px;
    }
  }

  .vertical-img {
    max-height: 209px;
  }

  .vertical-media {
    position: relative;
    overflow: visible;
    .vertical-img {
      max-width: 292px;
      overflow: hidden;
    }
    .media-title {

      border-bottom-right-radius: 10px;
      overflow: hidden;
      width: 292px;
      padding: 5px 5px;
      .box-arrow {
        background: url('@{assets_imgs}box-arrow.png') no-repeat;
        position: absolute;
        top: 194px;
        left: 136.5px;
        display: block;
        width: 20px;
        height: 15px;
      }
      .title-sizer {
        display: table-cell;
        vertical-align: middle;
        width: 283px;
        text-align: center;
      }
    }
    .media-body {
      position: absolute;
      top: 0;
      left: 0;
      border: none;
      padding: 0;
      overflow: visible;
      width: 292px;
      height: 200px;
      max-height: 200px;
      .media-content {
        background: rgb(255, 255, 255);
        background: rgba(255, 255, 255, 0.9);
        display: none;
        width: 292px;
        height: 209px;
        max-height: 209px;
        padding: 10px 10px;
        overflow: hidden;
        p {
          max-height: 91px;
          overflow: hidden;
        }
      }
      .insight-label {
        bottom: 5px;
        right: -9px;
      }
    }
    &:hover {
      .media-title .box-arrow {
        display: none;
      }
      .media-content {
        display: block;
      }
    }
  }

  .vertical-media-container {
    margin: 0 auto;
  }

  .blog-featured {
    margin: 0;
    padding: 0;
    .col-no-padding-right {
      padding-right: 0;
    }
    .col-no-padding-left {
      padding-left: 0;
    }
    .blog-image {
      padding: 0;
    }
    .image-holder {
      position: absolute;
    }
    .blog-image-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .featured-blog-holder {
      display: block;
      visibility: visible;
    }
  }
}

.slick-initialized {
  visibility: visible;
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg) {
  .slick-slideshow .slick-content .slick-content-pos .slick-heading {
    &.h1 {
      margin-bottom: 20px;
    }
    &.h2 {
      margin-bottom: 20px;
    }
  }
}

// Print



span.read-more,
.nav-tabs,
.pagination,
#map-div {
  .hidden-print();
}

blockquote {

    clear: both;
  }

@media print {

 

  a[href]:after,
  a.call-to-action:after {
    content: "" !important;
  }

  .call-to-action .cta-item {
    display: none;
  }
  .call-to-action .media {
    padding-left: 0;
    min-height: initial;
  }

  .hr-stripe {
    display: none;
  }

  .slick-carousel {
    padding: 0;
  }


  .slick-arrow {
    display: none !important;
  }

  .img-radius-top {
    border-radius: 0;
  }

  .media-slide-up .media-summary .media-content {
    border-width: 0;
  }

  .media-slide-up .media-summary {
    display: none !important;
    *:last-child {
      margin-bottom: 0;
    }
  }

  .media-slide {
    position: static;
    display: block !important;
    color: #000;
    a {
      color: #000;
    }
    &.media-content {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .slider-blog {
    border-width: 0;
    margin-right: 0 !important;
    margin-left: 0 !important;
    position: static;
  }

  #adv-custom-caption {
    margin-bottom: 0;
    min-height: unset;
  }

  #page-slideshow .cycle-pager {
    display: none !important;
  }

  [data-match-height], [data-mh] {
    height: auto !important;
  }

  .panel-content-block {
    .list-group,
    .panel-body {
      border-width: 0;
    }
  }

  .panel.pullquote {
    border-width: 0;
  }

  blockquote {
    border: 1px solid #999;
    clear: both;
  }

  .slider-blog-nav {
    display: none;
  }

  .tab-content {
    border-width: 0 !important;
    .tab-pane {
      display: block;
    }
  }

  .media-slide-link {
    position: static;
  }

  .thumbnail {
    padding: 0 !important;
    border-width: 0 !important;
  }

  .sidebar-details-box .label {
    border-width: 0;
    padding-left: 0;
    padding-right: 0;
  }

  #footer {
    position: static;
  }

  h1, .h1 {
    font-size: 20px;
  }

  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: @font-family-base;
    color: @text-color;
  }

  h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-size: @font-size-medium;
  }

  .panel {
    .panel-heading {
      background-color: transparent;
      padding: 0 !important;
      .panel-title {
        font-size: @font-size-medium;
        color: #000;
      }
    }
    .panel-body {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .panel-content-block {
    border: 1px solid @gray-lighter;
    .panel-heading {
      background-color: transparent;
      border-bottom: 1px solid @gray-lighter;
      .panel-title {
        color: @text-color;
      }

    }
  }

  .article-image > a {
    display: block;
    > img {
      width: 100%;
    }
  }

  .col-sm-2 {
    .make-xs-column(2);
    padding: 0;
  }

  .col-sm-4,
  .col-md-4 {
    .make-xs-column(4);
  }

  .col-sm-8,
  .col-md-8 {
    .make-xs-column(8);
  }
}

.media-news {
  @media (max-width: @screen-xs) {
    .media-img.pull-left {
      padding: 0 0 10px;
      display: block;
      float: none !important;
      > img {
          margin: 0 auto;
      }
    }
  }
}

.ajax-content {
  .page-heading h1 {
    small {
      margin-top: 10px;
      margin-bottom: 10px;
      display: block;
    }
  }

  .article {
    float: none !important;
    width: 100% !important;
  }
}

.ajax-content,
#mfp-newsletter.small,
#mfp-dialog.small {
  .clearfix;
  background: white;
  padding: 40px 20px;
  text-align: left;
  max-width: 740px;
  margin: 40px auto;
  position: relative;

  @media (min-width: @screen-xs) {
    padding: 40px 20px;
  }

  .section-title small {
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
  }
}
// Clear floats
.clear-left {
  clear: left;
}

@media (min-width: @screen-xs) and (max-width: @screen-md) {
  .clear-sm {
    clear: left;
  }
}

@media (min-width: @screen-md) {
  .clear-md {
    clear: left;
  }
}


/**
 * Fade-move animation for second dialog
 */

 /* at start */
 .my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  transition: all 0.2s ease-out;
  transform: translateY(-20px) perspective(600px) rotateX(10deg);
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  transform: translateY(0) perspective(600px) rotateX(0);
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;
  transform: translateY(-10px) perspective(600px) rotateX(10deg);
}

/* Dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0;
}

/**
 * Simple fade transition,
 */
 .mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.stretched-link:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0,0,0,0); 
}

// font-family
.font-family-base { font-family: @font-family-base; }
.font-family-secondary { font-family: @font-family-secondary; }
.font-family-tertiary { font-family: @font-family-alt; }

// font weight
.font-normal { font-weight: normal; }
.font-bold { font-weight: bold; }

.font-100 { font-weight: 100; }
.font-200 { font-weight: 300; }
.font-300 { font-weight: 300; }
.font-400 { font-weight: 400; }
.font-500 { font-weight: 500; }
.font-600 { font-weight: 600; }
.font-700 { font-weight: 700; }
.font-800 { font-weight: 800; }
.font-900 { font-weight: 900; }

// text color
.text-inherit { color: inherit; }
.text-orange { color: @giz-orange; }
.text-green { color: @giz-seagreen; }

.text-gray-100 { color: @gray-100; }
.text-gray-200 { color: @gray-200; }
.text-gray-300 { color: @gray-300; }
.text-gray-400 { color: @gray-400; }
.text-gray-500 { color: @gray-500; }

.text-white { color: @white; }
.text-light { color: @light; }
.text-gray { color: @dark; }
.text-dark { color: @gray; }
.text-body { color: @text-color; }

.text-green-100 { color: @green-100; }
.text-green-200 { color: @green-200; }
.text-green-300 { color: @green-300; }
.text-green-400 { color: @green-400; }
.text-green-500 { color: @green-500; }
.text-green-500-hover:hover { color: @green-500 !important; }
@media (min-width: @screen-md) {
  .text-md-green-500 { color: @green-500 !important; --color: @green-500; }
}

.text-green-600 { color: @green-600; }
.text-orange-100 { color: @orange-100; }
.text-orange-200 { color: @orange-200; }





// background colour
.bg-gray-100 { background-color: @gray-100; }
.bg-gray-200 { background-color: @gray-200; }
.bg-gray-300 { background-color: @gray-300; }
.bg-gray-400 { background-color: @gray-400; }
.bg-gray-500 { background-color: @gray-500; }

.bg-white { background-color: @white; }
.bg-light { background-color: @light; }
.bg-gray { background-color: @dark; }
.bg-dark { background-color: @gray; }

.bg-green-100 { background-color: @green-100 !important; --bg-color: @green-100; }
.bg-green-200 { background-color: @green-200 !important; --bg-color: @green-200; }
.bg-green-300 { background-color: @green-300 !important; --bg-color: @green-300; }
.bg-green-400 { background-color: @green-400 !important; --bg-color: @green-400; }
.bg-green-500 { background-color: @green-500 !important; --bg-color: @green-500; }
.bg-green-600 { background-color: @green-600 !important; --bg-color: @green-600; }

.bg-orange-100 { background-color: @orange-100 !important; --bg-color: @orange-100;}
.bg-orange-200 { background-color: @orange-200 !important; --bg-color: @orange-200;}


@media (min-width: @screen-md) {
  .bg-md-green-500 { background-color: @green-500 !important; --bg-color: @green-500; }
  .bg-md-white { background-color: @white !important; --bg-color: @white; }
}

@media (min-width: @screen-sm) {
  .bg-sm-transparent { background-color: transparent !important; }
}

@media (min-width: @screen-md) {
  .bg-md-transparent { background-color: transparent !important; }
}


.border { border: 1px solid @gray-100; }
// border colour
.border-gray-100 { border-color: @gray-100; }
.border-gray-200 { border-color: @gray-200; }
.border-gray-300 { border-color: @gray-300; }
.border-gray-400 { border-color: @gray-400; }
.border-gray-500 { border-color: @gray-500; }

.border-white { border-color: @white; }
.border-light { border-color: @light; }
.border-gray { border-color: @dark; }
.border-dark { border-color: @gray; }

.border-green-100 { border-color: @green-100 !important; }
.border-green-200 { border-color: @green-200 !important; }
.border-green-300 { border-color: @green-300 !important; }
.border-green-400 { border-color: @green-400 !important; }
.border-green-500 { border-color: @green-500 !important; }
.border-green-600 { border-color: @green-600 !important; }

.border-orange-100 { border-color: @orange-100 !important; }
.border-orange-200 { border-color: @orange-200 !important; }

.border-3 {
  border-width: 3px;
}

.border-t-0	{ border-top-width: 0 !important; }
.border-r-0	{ border-right-width: 0 !important; }
.border-b-0	{ border-bottom-width: 0 !important; }
.border-l-0	{ border-left-width: 0 !important; }

.rounded-none	{ border-radius: 0 !important; }

.btn-green-100 { .button-variant(@white; @green-100; @green-100); }
.btn-green-500 { .button-variant(@white; @green-500; @green-500); }
.btn-green-600 { .button-variant(@white; @green-600; @green-600); }

.btn-orange-100 { .button-variant(@white; @orange-100; @orange-100); }
.btn-orange-200 { .button-variant(@white; @orange-200; @orange-200); }

.border-dashed { border-style: dashed; }
.border-dotted { border-style: dotted; }
.border-none { border-style: none; }

// spacing
@spacer-1: (@line-height-computed * 0.25);
@spacer-2: (@line-height-computed * 0.5);
@spacer-3: (@line-height-computed * 0.75);
@spacer-4: (@line-height-computed * 1);
@spacer-5: (@line-height-computed * 1.5);
@spacer-6: (@line-height-computed * 2.25);

// margins
.mt-0 { margin-top: 0; }
.mt-1 { margin-top: @spacer-1; }
.mt-2 { margin-top: @spacer-2; }
.mt-3 { margin-top: @spacer-3; }
.mt-4 { margin-top: @spacer-4; }
.mt-5 { margin-top: @spacer-5; }
.mt-6 { margin-top: @spacer-6; }

@media (min-width: @screen-xs) {
  .mt-xs-3 { margin-top: @spacer-3 !important; }
  .mt-xs-4 { margin-top: @spacer-4 !important; }
  .mt-xs-5 { margin-top: @spacer-5 !important; }
}
@media (min-width: @screen-sm) {
  .mt-sm-4 { margin-top: @spacer-4 !important; }
  .mt-sm-5 { margin-top: @spacer-5 !important; }
}

@media (min-width: @screen-md) {
  .mt-md-4 { margin-top: @spacer-4 !important; }
}

.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: @spacer-1; }
.mb-2 { margin-bottom: @spacer-2; }
.mb-3 { margin-bottom: @spacer-3; }
.mb-4 { margin-bottom: @spacer-4; }
.mb-5 { margin-bottom: @spacer-5; }
.mb-6 { margin-bottom: @spacer-6; }
.mb-lastchild-0 > *:last-child { margin-bottom: 0; }

@media (min-width: @screen-sm) {
  .mb-sm-0 { margin-bottom: 0 !important; }
}

@media (min-width: @screen-md) {
  .mb-md-0 { margin-bottom: 0 !important; }
}

.ml-auto { margin-left: auto; }
.ml-0 { margin-left: 0; }
.ml-1 { margin-left: @spacer-1; }
.ml-2 { margin-left: @spacer-2; }
.ml-3 { margin-left: @spacer-3; }
.ml-4 { margin-left: @spacer-4; }
.ml-5 { margin-left: @spacer-5; }
.ml-6 { margin-left: @spacer-6; }

.mr-auto { margin-right: auto; }
.mr-0 { margin-right: 0; }
.mr-1 { margin-right: @spacer-1; }
.mr-n1 { margin-right: -(@spacer-1); }
.mr-2 { margin-right: @spacer-2; }
.mr-3 { margin-right: @spacer-3; }
.mr-4 { margin-right: @spacer-4; }
.mr-5 { margin-right: @spacer-5; }
.mr-6 { margin-right: @spacer-6; }

@media (min-width: @screen-xs) {
  .mr-xs-3 { margin-right: @spacer-3 !important; }
  .mr-xs-4 { margin-right: @spacer-4 !important; }
  .mr-xs-5 { margin-right: @spacer-5 !important; }
}

.mx-0 { margin-left: 0; margin-right: 0; }
.mx-1 { margin-left: @spacer-1; margin-right: @spacer-1; }
.mx-2 { margin-left: @spacer-2; margin-right: @spacer-2; }
.mx-3 { margin-left: @spacer-3; margin-right: @spacer-3; }
.mx-4 { margin-left: @spacer-4; margin-right: @spacer-4; }
.mx-5 { margin-left: @spacer-5; margin-right: @spacer-5; }
.mx-6 { margin-left: @spacer-6; margin-right: @spacer-6; }

.my-0 { margin-top: 0; margin-bottom: 0; }
.my-1 { margin-top: @spacer-1; margin-bottom: @spacer-1; }
.my-2 { margin-top: @spacer-2; margin-bottom: @spacer-2; }
.my-3 { margin-top: @spacer-3; margin-bottom: @spacer-3; }
.my-4 { margin-top: @spacer-4; margin-bottom: @spacer-4; }
.my-5 { margin-top: @spacer-5; margin-bottom: @spacer-5; }
.my-6 { margin-top: @spacer-6; margin-bottom: @spacer-6; }

// padding
.p-0 { padding: 0 !important; }
.p-1 { padding: @spacer-1; }
.p-2 { padding: @spacer-2; }
.p-3 { padding: @spacer-3; }
.p-4 { padding: @spacer-4; }
.p-5 { padding: @spacer-5; }
.p-6 { padding: @spacer-6; }

.pt-0 { padding-top: 0; }
.pt-1 { padding-top: @spacer-1; }
.pt-2 { padding-top: @spacer-2; }
.pt-3 { padding-top: @spacer-3; }
.pt-4 { padding-top: @spacer-4; }
.pt-5 { padding-top: @spacer-5; }
.pt-6 { padding-top: @spacer-6; }

.pb-0 { padding-bottom: 0 !important; }
.pb-1 { padding-bottom: @spacer-1; }
.pb-2 { padding-bottom: @spacer-2; }
.pb-3 { padding-bottom: @spacer-3; }
.pb-4 { padding-bottom: @spacer-4; }
.pb-5 { padding-bottom: @spacer-5; }
.pb-6 { padding-bottom: @spacer-6; }

.pl-0 { padding-left: 0;}
.pl-1 { padding-left: @spacer-1; }
.pl-2 { padding-left: @spacer-2; }
.pl-3 { padding-left: @spacer-3; }
.pl-4 { padding-left: @spacer-4; }
.pl-5 { padding-left: @spacer-5; }
.pl-6 { padding-left: @spacer-6; }

.pr-0 { padding-right: 0;}
.pr-1 { padding-right: @spacer-1; }
.pr-2 { padding-right: @spacer-2; }
.pr-3 { padding-right: @spacer-3; }
.pr-4 { padding-right: @spacer-4; }
.pr-5 { padding-right: @spacer-5; }
.pr-6 { padding-right: @spacer-6; }

.px-0 { padding-left: 0 !important; padding-right: 0 !important; }
.px-1 { padding-left: @spacer-1; padding-right: @spacer-1; }
.px-2 { padding-left: @spacer-2; padding-right: @spacer-2; }
.px-3 { padding-left: @spacer-3; padding-right: @spacer-3; }
.px-4 { padding-left: @spacer-4; padding-right: @spacer-4; }
.px-5 { padding-left: @spacer-5; padding-right: @spacer-5; }
.px-6 { padding-left: @spacer-6; padding-right: @spacer-6; }

.py-0 { padding-top: 0; padding-bottom: 0; }
.py-1 { padding-top: @spacer-1; padding-bottom: @spacer-1; }
.py-2 { padding-top: @spacer-2; padding-bottom: @spacer-2; }
.py-3 { padding-top: @spacer-3; padding-bottom: @spacer-3; }
.py-4 { padding-top: @spacer-4; padding-bottom: @spacer-4; }
.py-5 { padding-top: @spacer-5; padding-bottom: @spacer-5; }
.py-6 { padding-top: @spacer-6; padding-bottom: @spacer-6; }


.leading-none	{ line-height: 1 !important; }
.leading-tight	{ line-height: 1.25 !important; }
.leading-snug	{ line-height: 1.375 !important; }
.leading-normal	{ line-height: 1.5 !important; }
.leading-relaxed	{ line-height: 1.625 !important; }
.leading-loose	{ line-height: 2 !important; }

.text-xs	{ font-size: @font-size-small; }
.text-sm	{ font-size: @font-size-medium; }
.text-base	{ font-size: @font-size-base; }
.text-lg	{ font-size: @font-size-large; }

.underline	{ text-decoration: underline !important; }
.line-through	{ text-decoration: line-through !important; }
.no-underline	{ text-decoration: none !important; }


.static	{ position: static !important; }
.fixed	{ position: fixed !important; }
.absolute	{ position: absolute !important; }
.relative	{ position: relative !important; }
.sticky	{ position: sticky !important; }

@media (min-width: @screen-sm) {
  .absolute-sm { position: absolute !important; }
}

.top-0	{ top: 0 !important; }
.right-0	{ right: 0 !important; }
.bottom-0	{ bottom: 0 !important; }
.left-0	{ left: 0 !important; }
.top-auto	{ top: auto !important; }
.right-auto	{ right: auto !important; }
.bottom-auto	{ bottom: auto !important; }
.left-auto	{ left: auto !important; }

.z-0	{ z-index: 0 !important; }
.z-1	{ z-index: 1 !important; }
.z-2	{ z-index: 2 !important; }
.z-3	{ z-index: 3 !important; }
.z-auto	{ z-index: auto !important; }

.form-slide {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
}

.form-slide-control {
  position: relative;
  z-index: 2;
  transition: 250ms;
  display: block !important;
  @media (min-width: @screen-md) {
    width: 2.375rem !important;

    &:not(:focus) { border-color: transparent !important; }
    &:focus, &:not(:placeholder-shown) {
      width: 320px !important;
      max-width: 100%;
      flex: 1 1 auto!important;
      padding-right: 2.375rem;
    }
  }
}

.text-transparent { color: transparent; }
.border-inherit { border-color: inherit !important; }
.border-transparent { border-color: transparent !important; }

.shadow-none { box-shadow: none !important; }
.outline-focus-none:focus { outline: 0 !important; }

.rounded-none	{ border-radius: 0 !important; }
.rounded-sm	{ border-radius: 3px !important; }
.rounded	{ border-radius: 4px !important; }
.rounded-md	{ border-radius: 6px !important; }
.rounded-lg	{ border-radius: 12px !important; }

