// Fonts
// --------------------------------------------------

@font-face{
	font-family:"St Ryde";
	src:url("/dist/fonts/d8fcfd91-1a7a-4fe3-ac92-2726ab0efce2.eot?#iefix") format("eot")
}
@font-face{
	font-family:"St Ryde";
	src:url("/dist/fonts/d8fcfd91-1a7a-4fe3-ac92-2726ab0efce2.eot?#iefix");
	src:url("/dist/fonts/d8fcfd91-1a7a-4fe3-ac92-2726ab0efce2.eot?#iefix") format("eot"),
	url("/dist/fonts/b9dcb589-e7ea-4675-b9b6-6458d214a9c7.woff") format("woff"),
	url("/dist/fonts/06ae6d63-de3b-4700-aba5-929f918ecaa5.ttf") format("truetype"),
	url("/dist/fonts/3b67774c-9c63-4b76-b7db-d9884bae3791.svg#3b67774c-9c63-4b76-b7db-d9884bae3791") format("svg");
	font-weight: 700;
	font-style: normal;
}
@font-face{
	font-family:"St Ryde";
	src:url("/dist/fonts/6fa430c5-cbee-4089-91d3-5977b3846c04.eot?#iefix") format("eot")
}
@font-face{
	font-family:"St Ryde";
	src:url("/dist/fonts/6fa430c5-cbee-4089-91d3-5977b3846c04.eot?#iefix");
	src:url("/dist/fonts/6fa430c5-cbee-4089-91d3-5977b3846c04.eot?#iefix") format("eot"),url("/dist/fonts/94f9ed76-d340-48c5-9a26-00d38e81af59.woff") format("woff"),url("/dist/fonts/bce561de-c17c-4788-89b0-daa9f55848b2.ttf") format("truetype"),url("/dist/fonts/8498886d-e42b-424d-95a5-c2eee9c8301a.svg#8498886d-e42b-424d-95a5-c2eee9c8301a") format("svg");
	font-weight: 400;
	font-style: italic;
}
@font-face{
	font-family:"St Ryde";
	src:url("/dist/fonts/8c22adf5-0fd9-4e62-8e2e-6ff732479a87.eot?#iefix") format("eot")
}
@font-face{
	font-family:"St Ryde";
	src:url("/dist/fonts/8c22adf5-0fd9-4e62-8e2e-6ff732479a87.eot?#iefix");
	src:url("/dist/fonts/8c22adf5-0fd9-4e62-8e2e-6ff732479a87.eot?#iefix") format("eot"),url("/dist/fonts/d1b36457-8148-45dd-8698-3dbeac99b08c.woff") format("woff"),url("/dist/fonts/2cf07e52-631c-4a7a-97ec-26340a960579.ttf") format("truetype"),url("/dist/fonts/9f9a8548-c2ee-43ba-a9bb-57b70816543f.svg#9f9a8548-c2ee-43ba-a9bb-57b70816543f") format("svg");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'CapsuulaRegular';
	src: url('/dist/fonts/capsuula.eot');
	src: url('/dist/fonts/capsuula.eot?#iefix') format('embedded-opentype'), url('/dist/fonts/capsuula.woff') format('woff'), url('/dist/fonts/capsuula.ttf') format('truetype'), url('/dist/fonts/capsuula.svg#CapsuulaRegular') format('svg');
}


@font-face {
    font-family: 'Venn SemiExtended';
    src: url('/dist/fonts/Venn-SemiExtendedMedium.eot');
    src: local('Venn SemiExtended Medium'), local('Venn-SemiExtendedMedium'),
        url('/dist/fonts/Venn-SemiExtendedMedium.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-SemiExtendedMedium.woff2') format('woff2'),
        url('/dist/fonts/Venn-SemiExtendedMedium.woff') format('woff'),
        url('/dist/fonts/Venn-SemiExtendedMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Venn Extended';
    src: url('/dist/fonts/Venn-ExtendedExtraBold.eot');
    src: local('Venn Extended ExtraBold'), local('Venn-ExtendedExtraBold'),
        url('/dist/fonts/Venn-ExtendedExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-ExtendedExtraBold.woff2') format('woff2'),
        url('/dist/fonts/Venn-ExtendedExtraBold.woff') format('woff'),
        url('/dist/fonts/Venn-ExtendedExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Venn Extended';
    src: url('/dist/fonts/Venn-ExtendedBold.eot');
    src: local('Venn Extended Bold'), local('Venn-ExtendedBold'),
        url('/dist/fonts/Venn-ExtendedBold.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-ExtendedBold.woff2') format('woff2'),
        url('/dist/fonts/Venn-ExtendedBold.woff') format('woff'),
        url('/dist/fonts/Venn-ExtendedBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Venn SemiExtended';
    src: url('/dist/fonts/Venn-SemiExtendedRegular.eot');
    src: local('Venn SemiExtended Regular'), local('Venn-SemiExtendedRegular'),
        url('/dist/fonts/Venn-SemiExtendedRegular.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-SemiExtendedRegular.woff2') format('woff2'),
        url('/dist/fonts/Venn-SemiExtendedRegular.woff') format('woff'),
        url('/dist/fonts/Venn-SemiExtendedRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Venn SemiCondensed';
    src: url('/dist/fonts/Venn-SemiCondensedExtraBold.eot');
    src: local('Venn SemiCondensed ExtraBold'), local('Venn-SemiCondensedExtraBold'),
        url('/dist/fonts/Venn-SemiCondensedExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-SemiCondensedExtraBold.woff2') format('woff2'),
        url('/dist/fonts/Venn-SemiCondensedExtraBold.woff') format('woff'),
        url('/dist/fonts/Venn-SemiCondensedExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Venn Condensed';
    src: url('/dist/fonts/Venn-CondensedExtraBold.eot');
    src: local('Venn Condensed ExtraBold'), local('Venn-CondensedExtraBold'),
        url('/dist/fonts/Venn-CondensedExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-CondensedExtraBold.woff2') format('woff2'),
        url('/dist/fonts/Venn-CondensedExtraBold.woff') format('woff'),
        url('/dist/fonts/Venn-CondensedExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Venn SemiExtended';
    src: url('/dist/fonts/Venn-SemiExtendedLight.eot');
    src: local('Venn SemiExtended Light'), local('Venn-SemiExtendedLight'),
        url('/dist/fonts/Venn-SemiExtendedLight.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-SemiExtendedLight.woff2') format('woff2'),
        url('/dist/fonts/Venn-SemiExtendedLight.woff') format('woff'),
        url('/dist/fonts/Venn-SemiExtendedLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Venn SemiCondensed';
    src: url('/dist/fonts/Venn-SemiCondensedBold.eot');
    src: local('Venn SemiCondensed Bold'), local('Venn-SemiCondensedBold'),
        url('/dist/fonts/Venn-SemiCondensedBold.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-SemiCondensedBold.woff2') format('woff2'),
        url('/dist/fonts/Venn-SemiCondensedBold.woff') format('woff'),
        url('/dist/fonts/Venn-SemiCondensedBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Venn SemiExtended';
    src: url('/dist/fonts/Venn-SemiExtendedExtraBold.eot');
    src: local('Venn SemiExtended ExtraBold'), local('Venn-SemiExtendedExtraBold'),
        url('/dist/fonts/Venn-SemiExtendedExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-SemiExtendedExtraBold.woff2') format('woff2'),
        url('/dist/fonts/Venn-SemiExtendedExtraBold.woff') format('woff'),
        url('/dist/fonts/Venn-SemiExtendedExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Venn Condensed';
    src: url('/dist/fonts/Venn-CondensedBold.eot');
    src: local('Venn Condensed Bold'), local('Venn-CondensedBold'),
        url('/dist/fonts/Venn-CondensedBold.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-CondensedBold.woff2') format('woff2'),
        url('/dist/fonts/Venn-CondensedBold.woff') format('woff'),
        url('/dist/fonts/Venn-CondensedBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Venn';
    src: url('/dist/fonts/Venn-Medium.eot');
    src: local('Venn Medium'), local('Venn-Medium'),
        url('/dist/fonts/Venn-Medium.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-Medium.woff2') format('woff2'),
        url('/dist/fonts/Venn-Medium.woff') format('woff'),
        url('/dist/fonts/Venn-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Venn SemiCondensed';
    src: url('/dist/fonts/Venn-SemiCondensedMedium.eot');
    src: local('Venn SemiCondensed Medium'), local('Venn-SemiCondensedMedium'),
        url('/dist/fonts/Venn-SemiCondensedMedium.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-SemiCondensedMedium.woff2') format('woff2'),
        url('/dist/fonts/Venn-SemiCondensedMedium.woff') format('woff'),
        url('/dist/fonts/Venn-SemiCondensedMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Venn';
    src: url('/dist/fonts/Venn-ExtraBold.eot');
    src: local('Venn ExtraBold'), local('Venn-ExtraBold'),
        url('/dist/fonts/Venn-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-ExtraBold.woff2') format('woff2'),
        url('/dist/fonts/Venn-ExtraBold.woff') format('woff'),
        url('/dist/fonts/Venn-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Venn SemiCondensed';
    src: url('/dist/fonts/Venn-SemiCondensedRegular.eot');
    src: local('Venn SemiCondensed Regular'), local('Venn-SemiCondensedRegular'),
        url('/dist/fonts/Venn-SemiCondensedRegular.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-SemiCondensedRegular.woff2') format('woff2'),
        url('/dist/fonts/Venn-SemiCondensedRegular.woff') format('woff'),
        url('/dist/fonts/Venn-SemiCondensedRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Venn';
    src: url('/dist/fonts/Venn-Regular.eot');
    src: local('Venn Regular'), local('Venn-Regular'),
        url('/dist/fonts/Venn-Regular.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-Regular.woff2') format('woff2'),
        url('/dist/fonts/Venn-Regular.woff') format('woff'),
        url('/dist/fonts/Venn-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Venn Extended';
    src: url('/dist/fonts/Venn-ExtendedRegular.eot');
    src: local('Venn Extended Regular'), local('Venn-ExtendedRegular'),
        url('/dist/fonts/Venn-ExtendedRegular.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-ExtendedRegular.woff2') format('woff2'),
        url('/dist/fonts/Venn-ExtendedRegular.woff') format('woff'),
        url('/dist/fonts/Venn-ExtendedRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Venn Condensed';
    src: url('/dist/fonts/Venn-CondensedMedium.eot');
    src: local('Venn Condensed Medium'), local('Venn-CondensedMedium'),
        url('/dist/fonts/Venn-CondensedMedium.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-CondensedMedium.woff2') format('woff2'),
        url('/dist/fonts/Venn-CondensedMedium.woff') format('woff'),
        url('/dist/fonts/Venn-CondensedMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Venn SemiExtended';
    src: url('/dist/fonts/Venn-SemiExtendedBold.eot');
    src: local('Venn SemiExtended Bold'), local('Venn-SemiExtendedBold'),
        url('/dist/fonts/Venn-SemiExtendedBold.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-SemiExtendedBold.woff2') format('woff2'),
        url('/dist/fonts/Venn-SemiExtendedBold.woff') format('woff'),
        url('/dist/fonts/Venn-SemiExtendedBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Venn Condensed';
    src: url('/dist/fonts/Venn-CondensedLight.eot');
    src: local('Venn Condensed Light'), local('Venn-CondensedLight'),
        url('/dist/fonts/Venn-CondensedLight.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-CondensedLight.woff2') format('woff2'),
        url('/dist/fonts/Venn-CondensedLight.woff') format('woff'),
        url('/dist/fonts/Venn-CondensedLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Venn Extended';
    src: url('/dist/fonts/Venn-ExtendedLight.eot');
    src: local('Venn Extended Light'), local('Venn-ExtendedLight'),
        url('/dist/fonts/Venn-ExtendedLight.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-ExtendedLight.woff2') format('woff2'),
        url('/dist/fonts/Venn-ExtendedLight.woff') format('woff'),
        url('/dist/fonts/Venn-ExtendedLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Venn';
    src: url('/dist/fonts/Venn-Light.eot');
    src: local('Venn Light'), local('Venn-Light'),
        url('/dist/fonts/Venn-Light.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-Light.woff2') format('woff2'),
        url('/dist/fonts/Venn-Light.woff') format('woff'),
        url('/dist/fonts/Venn-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Venn';
    src: url('/dist/fonts/Venn-Bold.eot');
    src: local('Venn Bold'), local('Venn-Bold'),
        url('/dist/fonts/Venn-Bold.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-Bold.woff2') format('woff2'),
        url('/dist/fonts/Venn-Bold.woff') format('woff'),
        url('/dist/fonts/Venn-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Venn SemiCondensed';
    src: url('/dist/fonts/Venn-SemiCondensedLight.eot');
    src: local('Venn SemiCondensed Light'), local('Venn-SemiCondensedLight'),
        url('/dist/fonts/Venn-SemiCondensedLight.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-SemiCondensedLight.woff2') format('woff2'),
        url('/dist/fonts/Venn-SemiCondensedLight.woff') format('woff'),
        url('/dist/fonts/Venn-SemiCondensedLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Venn Extended';
    src: url('/dist/fonts/Venn-ExtendedMedium.eot');
    src: local('Venn Extended Medium'), local('Venn-ExtendedMedium'),
        url('/dist/fonts/Venn-ExtendedMedium.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-ExtendedMedium.woff2') format('woff2'),
        url('/dist/fonts/Venn-ExtendedMedium.woff') format('woff'),
        url('/dist/fonts/Venn-ExtendedMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Venn Condensed';
    src: url('/dist/fonts/Venn-CondensedRegular.eot');
    src: local('Venn Condensed Regular'), local('Venn-CondensedRegular'),
        url('/dist/fonts/Venn-CondensedRegular.eot?#iefix') format('embedded-opentype'),
        url('/dist/fonts/Venn-CondensedRegular.woff2') format('woff2'),
        url('/dist/fonts/Venn-CondensedRegular.woff') format('woff'),
        url('/dist/fonts/Venn-CondensedRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

